body {
    margin: 0;
    font-size: 10px;
    font-family: "Lato", Helvetica Neue, Calibri, Helvetica, Arial, sans-serif !important;
}


a {
    background: transparent
}

a:active,
a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: bold
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2em;
    margin: 0.67em 0
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -0.5em
}

sub {
    bottom: -0.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 40px
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}



button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0
}




fieldset {

    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em
}

legend {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: bold
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td,
th {
    padding: 0
}



body {
    font-family: "Lato", Helvetica Neue, Calibri, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857;
    color: #606060;
    background-color: #fff
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

a {
    color: #428bca;
    text-decoration: none
}

a:hover,
a:focus {
    color: #2a6496;
    text-decoration: underline
}

figure {
    margin: 0
}

img {
    vertical-align: middle
}

.img-responsive {
    display: block;
    width: 100% \9;
    max-width: 100%;
    height: auto
}

.img-rounded {
    border-radius: 6px
}


.img-circle {
    border-radius: 50%
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #ececec
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
    font-weight: normal;
    line-height: 1;
    color: #b3b3b3
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 20px;
    margin-bottom: 10px
}

h1 small,
h1 .small,
.h1 small,
.h1 .small,
h2 small,
h2 .small,
.h2 small,
.h2 .small,
h3 small,
h3 .small,
.h3 small,
.h3 .small {
    font-size: 65%
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 10px;
    margin-bottom: 10px
}

h4 small,
h4 .small,
.h4 small,
.h4 .small,
h5 small,
h5 .small,
.h5 small,
.h5 .small,
h6 small,
h6 .small,
.h6 small,
.h6 .small {
    font-size: 75%
}

h1,
.h1 {
    font-size: 36px
}

h2,
.h2 {
    font-size: 30px;
}

h3,
.h3 {
    font-size: 24px
}

h4,
.h4 {
    font-size: 18px
}

h5,
.h5 {
    font-size: 14px
}

h6,
.h6 {
    font-size: 12px
}

p {
    margin: 0 0 10px
}

.lead {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4
}

@media (min-width: 768px) {
    .lead {
        font-size: 21px
    }
}


.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: gray;
    background-color: #fff;
    background-image: none;
    box-shadow: none !important;
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
}

.form-control::-moz-placeholder {
    color: #b3b3b3;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #b3b3b3
}


.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: #ececec;
    opacity: 1
}


.form-group {
    margin-bottom: 15px;

}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em
}

.label:empty {
    display: none
}


.page-signin,
.page-forgot {
    width: 80%;
    margin: 0 auto; 
}

.page-signin .signin-body,
.page-forgot .signin-body {
    text-align: center
}

.page-signin .signin-body .signin-form,
.page-forgot .signin-body .signin-form {
    margin: 0 auto;
    float: none;
}

.page-signin .signin-body .signin-logo,
.page-forgot .signin-body .signin-logo {
    margin-bottom: 50px
}

.page-signin .signin-body .form-control,
.page-forgot .signin-body .form-control {
    margin-bottom: 25px;
    height: 5rem;
    padding-bottom: 2rem;
    padding-left: 3.6rem;
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-radius: 0;
    font-size: 22px
}

.page-signin .signin-body .form-control:hover,
.page-forgot .signin-body .form-control:hover {
    border-bottom-color: #202020
}



.page-signin .signin-body h2,
.page-signin .signup-body h2,
.page-forgot .signin-body h2,
.page-forgot .signup-body h2 {
    font-size: 3rem
}

.page-signin .signin-body h3,
.page-signin .signup-body h3,
.page-forgot .signin-body h3,
.page-forgot .signup-body h3 {
    margin: 0 0 3rem
}

.page-signin .signin-body select,
.page-signin .signup-body select,
.page-forgot .signin-body select,
.page-forgot .signup-body select {
    height: 38px
}

.page-signin .signin-body .form-group,
.page-signin .signup-body .form-group,
.page-forgot .signin-body .form-group,
.page-forgot .signup-body .form-group {
    position: relative
}

.page-signin .signin-body .form-group .col-sm-2,
.page-signin .signup-body .form-group .col-sm-2,
.page-forgot .signin-body .form-group .col-sm-2,
.page-forgot .signup-body .form-group .col-sm-2 {
    height: 38px;
    display: table
}

.page-signin .signin-body .form-group .col-sm-2 label,
.page-signin .signup-body .form-group .col-sm-2 label,
.page-forgot .signin-body .form-group .col-sm-2 label,
.page-forgot .signup-body .form-group .col-sm-2 label {
    vertical-align: middle;
    display: table-cell
}

.page-signin .signin-body .form-group .col-sm-10 input,
.page-signin .signup-body .form-group .col-sm-10 input,
.page-forgot .signin-body .form-group .col-sm-10 input,
.page-forgot .signup-body .form-group .col-sm-10 input {
    height: 38px
}

.page-signin .signin-body .form-group .col-sm-10 .input-group-btn,
.page-signin .signup-body .form-group .col-sm-10 .input-group-btn,
.page-forgot .signin-body .form-group .col-sm-10 .input-group-btn,
.page-forgot .signup-body .form-group .col-sm-10 .input-group-btn {
    height: 36px
}

.page-signin .signin-body .form-group .col-sm-10 .input-group-btn a,
.page-signin .signup-body .form-group .col-sm-10 .input-group-btn a,
.page-forgot .signin-body .form-group .col-sm-10 .input-group-btn a,
.page-forgot .signup-body .form-group .col-sm-10 .input-group-btn a {
    height: 100%
}

.page-signin .signin-body .form-group .col-sm-10 .input-group-btn a span,
.page-signin .signup-body .form-group .col-sm-10 .input-group-btn a span,
.page-forgot .signin-body .form-group .col-sm-10 .input-group-btn a span,
.page-forgot .signup-body .form-group .col-sm-10 .input-group-btn a span {
    padding-top: 6px;
    padding-left: 3px;
    top: 0
}

.page-signin .signin-body .form-group .glyphicon,
.page-signin .signup-body .form-group .glyphicon,
.page-forgot .signin-body .form-group .glyphicon,
.page-forgot .signup-body .form-group .glyphicon {
    position: absolute;
    top: 27%;
    left: 0;
    font-size: 2.4rem
}

.page-signin .signin-body .btn-info,
.page-signin .signup-body .btn-info,
.page-forgot .signin-body .btn-info,
.page-forgot .signup-body .btn-info {
    padding: 36px 48px;
    background-color: #00835E;
    margin-top: 15px
}

.page-signin .signin-body .btn-info__back,
.page-signin .signup-body .btn-info__back,
.page-forgot .signin-body .btn-info__back,
.page-forgot .signup-body .btn-info__back {
    background-color: #31b0d5
}

.page-signin .signin-body .btn-info[type=submit],
.page-signin .signup-body .btn-info[type=submit],
.page-forgot .signin-body .btn-info[type=submit],
.page-forgot .signup-body .btn-info[type=submit] {
    padding: 36px 0;
    background-color: #00835E
}

.page-signin .signin-body .btn-remove,
.page-signin .signup-body .btn-remove,
.page-forgot .signin-body .btn-remove,
.page-forgot .signup-body .btn-remove {
    height: 38px;
    background-color: #d9534f;
    color: white;
    width: 100%;
    line-height: 23px
}

.page-signin .signin-body .btn-primary,
.page-signin .signup-body .btn-primary,
.page-forgot .signin-body .btn-primary,
.page-forgot .signup-body .btn-primary {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 90%;
    padding: 12px 18px;
    background-color: white;
    width: 90%;
    padding: 12px 18px;
    background-color: white;
    color: #2DB288;
    border: 2px solid #2DB288;
    border-radius: 0;
    font-size: 16px;
    font-weight: bold;

    color: #2DB288;

    letter-spacing: 0.04em;
    text-transform: uppercase
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: #2DB288 !important;
    border-color: #2DB288 !important;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.page-signin .signin-body .btn-primary:hover,
.page-signin .signup-body .btn-primary:hover,
.page-forgot .signin-body .btn-primary:hover,
.page-forgot .signup-body .btn-primary:hover {
    background-color: #2DB288;
    color: white;
    text-decoration: none
}

.page-signin .signin-body .signin-text,
.page-signin .signup-body .signin-text,
.page-forgot .signin-body .signin-text,
.page-forgot .signup-body .signin-text {
    padding-top: 18px
}

.page-signin .signin-body .signin-text a,
.page-signin .signup-body .signin-text a,
.page-forgot .signin-body .signin-text a,
.page-forgot .signup-body .signin-text a {
    color: #289e78
}

.page-signin .signin-error-box,
.page-forgot .signin-error-box {
    text-align: center;
    margin-bottom: 10px
}


.page-signin .signin-footer,
.page-forgot .signin-footer {
    margin-bottom: 50px;
    font-size: small;
    width: 75%;
    margin: 0 auto;
    margin-top: 2rem;
}

.page-signin .signin-footer p a,
.page-forgot .signin-footer p a {
    color: #1b6d85;
    text-decoration: none;
}

.page-signin .signin-footer img,
.page-forgot .signin-footer img {
    width: 40px;
}

@media (min-width: 992px) {

    .page-signin,
    .page-forgot {
        margin-top: 20vh
    }

    .page-signin--modal,
    .page-forgot--modal {
        margin-top: 5vh
    }
}

.page-signup .signup-logo {
    margin: 3rem 0
}

.banner {
    width: calc(100% - 30px);
    display: block;
    background-position: center;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: 3s;
    background-size: contain;
    background-repeat: no-repeat
}

.visible {
    opacity: 1;
    z-index: 5
}

.incompliant {
    color: #ff0000
}

.page-signin .signin-body .form-group .glyphicon,
.page-signin .signup-body .form-group .glyphicon,
.page-forgot .signin-body .form-group .glyphicon,
.page-forgot .signup-body .form-group .glyphicon {
    position: absolute;
    top: 10%;
    left: 0;
    font-size: 2.4rem;
    color: #606060;
    background-color: #fff;

    .hidden-input:focus+.underline {
        background-color: rgba(0, 0, 0, 0.8);
        /* / Highlight underline on focus / */
    }

    ;
}

.page-signin .signin-body,
.page-forgot .signin-body {
    text-align: center;
}

.page-signin .signin-body .form-control,
.page-forgot .signin-body .form-control {
    margin-bottom: 3rem;
    color: gray;
    padding-left: 3.6rem;
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-radius: 0;
    font-size: 22px;
}

/* / Disable focus on all input fields / */
input,
textarea,
select {
    outline: none;
    color: gray;
    /* / Remove the default focus outline / */
}



.page-signin .signin-body .signin-logo,
.page-forgot .signin-body .signin-logo {
    margin-bottom: 15px;
    margin-top: 15px;
}

.form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px;
}


.page-signin .signin-body,
.page-forgot .signin-body {
    text-align: center;
}

.form-group {
    width: 50% !important;
    margin: auto !important;
}

.form-group.username-field {
    width: 50% !important;
    margin: auto !important;
}
.form-group.password-field {
    width: 50% !important;
    margin: auto !important;
}

.password-field {
    margin-top: 20px;
}

.form-control,
.input-lg {
    color: gray;
}

.text-infoo {
    text-decoration: none;
    font-size: 14px;
    color: #3B799A !important;
}

.form-div {
    margin: 0px 75px 0px 75px;
}

/* header css start */

#header {
    background-color: #047D62;
    color: white !important;
    font-size: 14px;
    padding-top: 6px;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

ul.nav-left.pull-right {
    display: flex !important;
    list-style: none !important;
}

.header-section {
    display: flex;
}

.pull-left .logo-facility-name {
    font-size: 18px;
}

input.header-input {
    margin-left: 5px;
    margin-right: 5px;
}

.toggle-min i.fa.fa-bars {
    font-size: 1.2em;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
}

span.pull-left.logo-facility-name {
    font-size: 1.5em;
    padding-top: 2px;
    padding-left: 15px;
}

div.w-full.p-2.border.rounded {
    width: 260px !important;
    margin-right: 12px;
}

input.w-full.p-2.border.rounded {
    width: 260px !important;
    margin-right: 12px;
}


a.faq {
    color: white !important;
    text-decoration: none !important;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 33px;
}

a.btn.btn-primary {
    color: #2DB288 !important;
    text-decoration: none !important;
}

a.btn.btn-primary:hover {
    color: white !important;
    text-decoration: none !important;
}

a {
    color: #2DB288;
    text-decoration: none !important;

}

li.text-normal {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 5px;
}

.header-name {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
}

.logout-button {
    padding-top: 4px;
    font-size: 18px;
}

.logout-text {
    padding: 14px 15px;
    color: white !important;
    font-size: 1.1em;
    text-decoration: none;
}

.logout-text:hover {
    padding: 14px 15px;
    color: white !important;
    font-size: 1.1em;
    text-decoration: none;
}

.logout-text:active {
    padding: 14px 15px;
    color: white !important;
    font-size: 1.1em;
    text-decoration: none;
}

/* header css end */

/* Css for toggle sidebar start */
#root.nav-collapsed-min .sidebar {
    width: 50px;
}

@media (min-width: 768px) {

    /* Targeting the anchor tags in the nav list when sidebar is collapsed */
    #root.nav-collapsed-min .main-container .content-container .sidebar .nav-wrapper #nav li a {
        padding: 15px;
        text-align: center;
    }

    /* Hiding the span elements in the nav list when sidebar is collapsed */
    #root.nav-collapsed-min .main-container .content-container .sidebar .nav-wrapper #nav li span {
        display: none;
    }
}

@media (min-width: 469px) and (max-width:768px) {

    /* Targeting the anchor tags in the nav list when sidebar is collapsed */
    #root.nav-collapsed-min .main-container .content-container .sidebar .nav-wrapper #nav li a {
        padding: 15px;
        text-align: center;
    }

    /* Hiding the span elements in the nav list when sidebar is collapsed */
    #root.nav-collapsed-min .main-container .content-container .sidebar .nav-wrapper #nav li span {
        display: none;
    }
}


/* Css for toggle sidebar end */

/* Sidebar css Start */

.sidebar {
    background-color: #000000ed !important;
    width: 220px;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.nav-wrapper .nav {
    display: block;
}

.nav-wrapper .nav li {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
    font-weight: bold;
}

.nav-wrapper .nav li:hover {
    background-color: #000000ed !important;
    color: white !important;
}

.nav-wrapper .nav a {
    display: block;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    color: white !important;
    background-color: transparent !important;
    transition: background-color 0.5s, color 0.5s;
    text-decoration: none !important;
}

.nav-wrapper .nav a:hover {
    display: block;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    color: white !important;
    background-color: transparent !important;
    transition: background-color 0.5s, color 0.5s;
    text-decoration: none !important;
}

.nav-wrapper .nav>li>a .nav-icon {
    font-size: 1.6rem;
    transition: transform 0.3s;
}

.nav-wrapper .nav a .nav-icon {
    margin-right: .75rem;
}

li.nav-header {
    background-color: #000000ed !important;
    color: white !important;
    font-weight: bold;
    font-size: 1.4em;
    text-align: center;
    padding: 1rem 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

li.nav-header:hover {
    background-color: #000000ed !important;
    color: white !important;
    font-weight: bold;
    font-size: 1.4em;
    text-align: center;
    padding: 1rem 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}


/* Sidebar css end */







/* This css for signup page */
.col {
    text-align: center;
}

.text-center {
    text-align: center;
    margin-bottom: 20px;
    /* Optional: Adds space below the heading */
    font-size: 24px;
    /* Optional: Adjusts the font size */
    color: gray;
    font-weight: 450;
    /* Optional: Makes the text bold */
}

.form-div {

    justify-content: center;
    align-items: center;

    margin-top: 20px;
    /* Optional: Adds space above the form */
}

.h3,
h3 {
    margin-left: -10px;
    font-size: x-large;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

/* Resize the custom switch toggle */
.switch input {
    display: none;
}

.switch i {
    width: 60px;
    /* Increase or decrease the width of the switch */
    height: 30px;
    /* Increase or decrease the height of the switch */
    border-radius: 20px;
    /* Rounded corners */
    background-color: #ccc;
    /* Default off color */
    position: relative;
    transition: all 0.3s ease;
}

/* Resize the thumb (circle) inside the switch */
.switch i::before {
    content: '';
    position: absolute;
    width: 10px;
    /* Adjust the thumb size */
    height: 20px;
    /* Adjust the thumb size */
    border-radius: 50%;
    /* Circular thumb */
    background-color: #fff;
    /* Thumb color */
    transition: all 0.3s ease;
}

/* When the switch is checked (on state) */
.switch input:checked+i {
    background-color: #4CAF50;
    /* On color (green) */
}

/* Move the thumb when the switch is checked */
.switch input:checked+i::before {
    transform: translateX(20px);
    /* Adjust the distance the thumb moves */
}




/* General styling for form sections */
.form-section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

/* Label styling */
.form-section .col-sm-2 {
    width: 16.66666667%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
}

/* Input field styling */
.form-section .col-sm-10 {
    width: 83.33333333%;
}

/* Styling for form inputs */
.form-section .form-control {
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-top: 5px;
}

/* Styling for input fields when focused */
.form-section .form-control:focus {
    border-color: #5b9bd5;
    outline: none;
}

/* Styling for larger input fields */
.form-control.input-lg {
    font-size: 18px;
}

/* Add some space between the sections */
.form-section {
    margin-bottom: 20px;
}

/* Add responsiveness */
@media (max-width: 767px) {

    .form-section .col-sm-2,
    .form-section .col-sm-10 {
        width: 100%;
    }

    .form-section .col-sm-2 {
        text-align: left;
    }

    .form-section .form-control {
        font-size: 14px;
    }

    a.resident.btn.btn-info.btn-print {
        margin-top: 13px;
        width: auto;
        height: 34px;
        background-color: #31b0d5;
        border-color: #31b0d5;
        color: white;
        font-size: 15px;
        float: right;
    }

    .col-sm-3.p-2 {
        width: 25% !important;
    }

    .col-sm-1.p-2 {
        width: 8.33333333% !important;
    }

    .col-sm-1.resident-label-div {
        width: 100% !important;
    }

    .col-sm-5 {
        width: 100% !important;
    }

    .col-sm-9.p-2 {
        width: 100% !important;
    }
    table.key-table tbody tr td {
        font-size: 13px;
    }
    
    .message-box {
        width: 100% !important;
        margin: auto;
        padding: 20px;
        border: 2px solid #e1e126;
        border-radius: 5px;
        background-color: #f7f7dc;
        margin-bottom: 20px;
    }
}

/* Label styles */
label {
    display: inline-block;
    margin-bottom: 5px;
    line-height: 1.42857;
    font-size: 14px;
    color: #606060;
}

/* Column styling */
.col-md-6 {
    width: 50%;
}

/* Button Styling */
button.btn.btn-info.btn-block.btn-lg {
    font-size: 16px;
    padding: 36px 48px;
    width: 100%;
    border-radius: 4px;
    background-color: #5bc0de;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
}

/* Button hover effect */
button.btn:hover {
    background-color: #31b0d5;
}

/* Styling for panel section */
.panel-default {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    border-bottom: inset;
}

.panel-headings {

    padding: 10px;
    font-size: 14px;
    border-color: #eee;
}

.panel-body {
    padding: 15px;
}

/* Table styles for services section */
table.table {
    width: 100%;
    margin-bottom: 15px;
    font-size: 15px;
    color: #3c3939;
}

table.table td {
    text-align: center;
    padding: 8px;
    font-size: 14px;
    border-color: #eee;
}

table.table .switch {
    display: inline-block;
}

.switch input[type="checkbox"] {
    display: none;
}

.switch i {
    display: inline-block;
    cursor: pointer;
    padding-right: 25px;
    transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    border-radius: 30px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

.switch input[type="checkbox"]:checked+i {
    background-color: #5bc0de;
}

.switch i:before {
    display: block;
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.switch input[type="checkbox"]:checked+i:before {
    left: 16px;
}

/* Styling for terms and conditions text */
a {
    color: #5bc0de;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.ng-binding {
    color: #606060;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: small;
    text-decoration: none;
}

/* Full-page overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    z-index: 1000;
    /* Higher than other elements */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Centered loader */
.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Blur effect on background */

button.btn.btn-info.btn-block.btn-lg {
    color: white;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    /* background-color: #31b0d5 !important;
    border-color: #31b0d5 !important; */
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

/* Header dropdown css start*/
.employee-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-top: 6px;
    z-index: 1000;
    overflow-y: auto; /* अगर content ज्यादा हो तो scroll bar दिखेगा */
    max-height: 190px; /* इसे अपने अनुसार एडजस्ट करें */
}



.employee-search-data {
    position: relative; /* Dropdown की absolute position के लिए */
  }

.course-dropdown {
    position: absolute !important;
    color: black;
    background-color: white;
    overflow-y: scroll;
    padding-top: 6px;
    z-index: 1000;
    height: 200px;
}

.option-values {
    padding: 3px 12px;
    cursor: pointer;
}

.option-values:hover {
    padding: 3px 12px;
    background-color: #f5f5f5;
}

.option-values:active {
    color: #ffffff;
    background-color: #428bca;
}

.search-bar {
    position: relative;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    color: #999;
    pointer-events: none;
    font-size: 1.2rem;
    cursor: pointer;
}

/* Header dropdown css end */

/* Master css start */

.content-container {
    display: flex;
    flex: 1;
    /* Take up the remaining height */
    /* overflow: hidden; */
    /* Prevent overflowing content */
}

.content-section {
    flex: 1;
    /* Take the remaining space */
    padding: 20px;
    overflow-y: auto;
    background-color: #F9F9F9;
}

/* MAster css end */

/* Task Table css start */

.panel-default {
    border-color: #eee;
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel .panel-heading strong {
    line-height: 3rem;
    font-family: "Oswald", Helvetica Neue, Calibri, Helvetica, Arial, sans-serif;
    font-size: 2.5rem;
    font-weight: 400;
    color: gray;
    text-transform: uppercase;
}

table>thead>tr>th>.th,
.table>thead>tr>th>.th {
    position: relative;
    padding: 8px;
    font-size: 1.4rem;
    color: #606060;
}

table>thead>tr>th>.th.has-pfo,
.table>thead>tr>th>.th.has-pfo {
    padding-right: 20px;
}

.label.label-outline {
    border: 1px solid gray;
    color: #737373 !important;
}

.task-pagination {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.modal-header.awiating {
    background-color: #fff;
    color: #606060;
}

button.pagination-button {
    color: #b3b3b3;
    background-color: #fff;
    border-color: #ddd;
    margin-left: 1px;
    padding: 5px 10px;
    font-size: 12px;
    border: 1px solid #ddd;
}

.page-buttons span {
    color: #fff;
    background-color: #428bca;
    border-color: #428bca;
    cursor: default;
    padding: 6px 10px;
    font-size: 12px;
    margin: 1px;
}

td.due_date {
    background: linear-gradient(to bottom, #de807d 25%, #dc7875 100%);
    color: white !important;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.075);
    font-weight: bold;
    text-align: center;
    border: none;
}

.div-task-table {
    display: flex;
    justify-content: space-around;
}

td.task-table-date-scheduled.text-center.ng-scope {
    padding-left: 0;
    padding-right: 0;
}

button.take-course {
    border: 1px solid green;
    background-color: white;
    border-radius: 2px;
    font-size: 10px;
    color: green;
}

button.schedulenow {
    font-size: 10px;
    border-radius: 2px;
    border: 1px solid #428bca;
    background-color: white;
    color: #428bca;
}

table.table-checkered>tbody>tr>td:nth-of-type(even),
.table.table-checkered>tbody>tr>td:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.0325);
}

.employee-col a {
    color: #606060;
}

table.table-checkered>thead>tr>th {
    color: #5e5a5a;
    font-size: 14px;
}

.col-md-7.panel-heading-actions.text-right span {
    font-size: 15px;
    text-transform: uppercase;
    color: #5e5c5c;
}

i.fa-solid.fa-print {
    color: #2a6496;
}

.no-data {
    height: 100px;
}

/* Task Table css end */

/* css for employee list Start */

.panels {
    /* margin-bottom: 20px; */
    /* background-color: #fff; */
    /* border: 1px solid transparent; */
    /* border-radius: 4px; */
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    width: 100%;
}

.panels .panel-heading {
    text-transform: uppercase;
}

.panels .panel-heading strong {
    line-height: 3rem;
    font-family: "Oswald", Helvetica Neue, Calibri, Helvetica, Arial, sans-serif;
    font-size: 2rem;
    font-weight: 400px;
    color: gray;
}

.panel-heading {
    padding: 10px 15px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-color: #eee;
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: medium;
}

/* Styling for the toggle switch */
.toggle-switchs {
    display: flex;
    /* Use flexbox for equal widths */
    justify-content: center;
    align-items: center;
    width: 80%;
    /* Ensure the toggle spans take up the full parent width */
    border: 1px solid #ccc;
    height: 30px;
    overflow: hidden;
    /* Prevent overflow if needed */
}

.toggle-switchs span {
    flex: 1;
    /* Each span takes up an equal part of the parent */
    text-align: center;
    /* Center the text */
    padding: 10px;
    /* Add some padding for better appearance */
    cursor: pointer;
    /* Indicate clickable behavior */
    transition: background-color 0.3s ease;
    /* Smooth background transition */
}

.switch-left.active {
    background-color: #66bb6a;
    ;
    /* Active color */
    color: white;
    /* Active text color */
}

.switch-right.inactive {
    background-color: #f44336;
    /* Inactive color */
    color: white;
    /* Inactive text color */
}


/* Styling for the search input */
.search-input {
    padding: 5px 10px;
    font-size: 14px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

/* Styling for the filter section */
.table-filters {
    margin-bottom: 20px;
}

/* Optional: Styling for the result info text */
.filter-result-info {
    font-size: 14px;
}

.table-dynamic .table-filters {
    margin: 10px 0;
    padding: 8px;
}

.panels>.table-responsive {
    border: 0;
    margin-bottom: 0;
    overflow-x: auto;
    overflow-y: hidden;
}

/* Basic table container styling */
.table-container {
    width: 100%;
    overflow: hidden;
    /* Prevent scrolling */
}

.panels {
    width: 100%;
    overflow: hidden;
    /* Hide scrollbars */
}

.data-table {
    width: 100%;
    /* Ensure table takes full width */
    table-layout: fixed;
    /* Prevent overflow */
}

/* Styling for action buttons */
.action-buttons {
    display: flex;
    gap: 10px;
}

.action-buttons button {
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Button Colors */
.action-buttons .view-btn {
    background-color: #17a2b8;
    color: white;
}

.action-buttons .edit-btn {
    background-color: #ffc107;
    color: white;
}

.action-buttons .delete-btn {
    background-color: #dc3545;
    color: white;
}

/* Avatar styling */
.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #888;
}

.avatar-image {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    display: flex
;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #888;
}

/* For the table image */
.avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.sc-hFflus.fAWkUP {
    display: block !important;
    /* or flex, grid, depending on your requirement */
}

.sc-hFflus .kAStla {
    display: block !important;
}

.sc-gaZyOd .sc-dDvxFM .sc-jqVXSH .epBJE .izXrzO .bhkpwP .rdt_TableCell button.btn.btn-info.btn-sm:active .btn:first-child:active {
    color: white !important;
    background-color: #159eb9 !important;
    border-color: #159eb9 !important;
}

.sc-jHbxoU .sc-cGXZpB .sc-jZCRgm .bNGfpQ .clUlAf .iTykKe .rdt_TableCell button.btn.btn-info.btn-sm:hover {
    background-color: #31b0d5 !important;
}

.sc-jHbxoU .sc-cGXZpB .sc-jZCRgm .bNGfpQ .clUlAf .iTykKe .rdt_TableCell button.btn.btn-warning.btn-sm:hover {
    background-color: #a8aa16 !important;
}

.sc-jHbxoU .sc-cGXZpB .sc-jZCRgm .bNGfpQ .clUlAf .iTykKe .rdt_TableCell button.btn.btn-danger.btn-sm:hover {
    background-color: #971009 !important;
}



/*Css for employee list end */

/* Css for task compliance start */

i.glyphicon.glyphicon-user.date-expired {
    color: #c65353;
    padding: 2px;
}

i.glyphicon.glyphicon-user.date-soon-expired {
    color: #f2aa41;
    padding: 2px;
}

span.task-type-name {
    font-size: 16px;
    color: #828181;
}

td.user-icon-col {
    width: 25%;
    padding: .85rem 1.25rem;
}

td.type-col {
    padding: .85rem 1.25rem;
}

/* Css for task compliance end *
/* Css for Employee list view */
#employee-detail-heading .panel-profile span.edit-widgets {
    position: absolute;
    top: 0;
    right: 0;
}

#employee-detail-heading .panel-profile span.edit-widgets span {
    margin: 5px;
}

#employee-detail-heading .panel-profile span.edit-widgets a {
    color: #767676 !important;
    font-size: x-large;
    margin: 1rem;
}

/* Css for Employee list view end */

/* Css for Resident */
.resident-panel-heading {
    padding: 6px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-color: #eee;
    line-height: 3rem;
    font-family: "Oswald", Helvetica Neue, Calibri, Helvetica, Arial, sans-serif;
    font-size: 2rem;
    font-weight: 400;
    color: gray;
}

.resident-card {
    width: 75%;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: Add a shadow */
    border: 1px solid #ddd;
    border-radius: 8px;
}


.current-resident.col-md-9.text-right {
    font-size: 15px;
    margin-bottom: 18px;
}

.resident-detail {
    font-size: 15px;
    font-weight: 400;
    color: gray;
    padding: 5px 5px 5px 8px;
}

.resident-table-container {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.resident-table {
    width: 100%;
    overflow-x: auto;
}

.table-wrapper {
    overflow-x: auto;
    /* Make the table scrollable on smaller screens */
}

.resident-table th {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    background-color: #f9f9f9;
    border-bottom: 2px solid #ddd;
}

.resident-table td {
    font-size: 15px;
    padding: 10px;
    color: grey;
    border-bottom: 1px solid #eee;
    white-space: nowrap;
    /* Prevent long text from wrapping */
}

.resident-table tr:hover {
    background-color: #f5f5f5;
}

.resident-table th,
.resident-table td {
    text-align: left;
}

.text-green-600 {
    color: #16a34a;
    /* Green */
}

.text-red-600 {
    color: #dc2626;
    /* Red */
}

.resident-tri-annual-panel-heading {
    padding: 6px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-color: #eee;
    line-height: 3rem;
    font-family: "Oswald", Helvetica Neue, Calibri, Helvetica, Arial, sans-serif;
    font-size: 2rem;
    font-weight: 400;
    color: gray;
}

.resident-tri-annual-card {
    margin-top: 32px;
    width: 75%;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: Add a shadow */
    border: 1px solid #ddd;
    border-radius: 8px;
}


.residentall.col-md-4 {
    padding: 17px 2px 1px 24px;
}

a.resident.btn.btn-info.btn-print {
    margin-top: 13px;
    width: auto;
    height: 34px;
    background-color: #31b0d5;
    border-color: #31b0d5;
    color: white;
    font-size: 15px;
}

i.ResidentDueimmediate {
    font-size: 13px;
}

.resident-link {
    color: inherit;
    /* Keeps the color same as parent */
    text-decoration: none;
    /* Prevents underline */
}

.resident-link:hover,
.resident-link:focus,
.resident-link:visited {
    color: inherit;
    /* Prevents any color changes on hover, focus, or visited */
    text-decoration: none;
    /* Keeps it unstyled */
}


/* css for resident dashboard end *

/* css for edit resident start */
section.resident-nav-section {
    font-size: 19px !important;
}

a.facesheetbtn.btn.btn-primary {
    float: right;
    font-size: 15px;
    color: white !important;
    background-color: #5bc0de;
    border-color: #46b8da;
}

a.facesheetbtn.btn.btn-primary:hover {
    float: right;
    font-size: 15px;
    color: white !important;
    background-color: #2e9bbc;
    border-color: #2497ba;
}

a.facesheetbtn.btn.btn-primary:active {
    float: right;
    font-size: 15px;
    color: white !important;
    background-color: #2e9bbc;
    border-color: #2497ba;
}

select.select-examiner.form-control {
    font-size: 13px !important;
}

button.btn.btn-success.resident-examiner {
    font-size: 15px !important;
    float: left !important;
    margin-top: -41px !important;
    margin-left: 14px !important;
}

button.resident-list-exam.btn.btn-primary {
    font-size: 13px !important;
}

button.resident-request-exam.btn.btn-primary {
    font-size: 14px !important;
}

button.resident-edit-resident-1823-blank-form-btn.btn {
    background-color: #30B0D5;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    font-size: 15px;
    float: right;
    margin-right: 1rem;
}

button.resident-edit-resident-1823-form-btn.btn {
    background-color: #30B0D5;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    font-size: 15px;
    float: right;
    margin-left: 1rem;
}

.resident-edit-1823-form .form-group {
    display: flex;
    width: 100% !important;
    margin-bottom: 15px;
}

.resident-edit-1823-form .form-control {
    width: 100%;
    box-sizing: border-box;
}

.resident-edit-1823-form .resident-label {
    display: inline-block;
    margin-bottom: 0;
    width: auto;
    padding-right: 10px;
    flex: 0 0 30%;
}

input.edit-1823form.form-control {
    font-size: 15px;
    font-weight: 400;
}

.physician-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.physician-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: auto !important;
}

button.upload-medication.btn.btn-primary {
    font-size: 16px !important;
    margin-left: 12px !important;
    background-color: #2F71A8 !important;
}

button.canvasign.btn.btn-secondary {
    background-color: #30B0D5 !important;
}

.physician-modal-content button {
    margin-top: 10px;
    padding: 5px 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
}

.btn-invited {
    background-color: #ffc107 !important;
    /* Custom Yellow */
    color: black !important;
    border: 1px solid #ffc107;
}

.btn-invited:hover {
    background-color: #e0a800 !important;
    /* Darker Yellow */
}


h5.\31 823facilitydata {
    font-size: 14px;
    margin-bottom: 13px;
    font-family: "Lato", Helvetica Neue, Calibri, Helvetica, Arial, sans-serif;
}

p.facility1823name {
    font-size: 15px !important;
}

a.btn.btn-success.call-facility {
    margin-left: 12px;
    background-color: #439D44 !important;
}

button.medication.btn.btn-primary {
    font-size: 16px !important;
    background-color: #2F71A8;
}

strong.checkresident {
    font-size: 17px !important;
}

input.medication.form-control {
    font-size: 14px !important;
}

button.canvasign.btn.btn-secondary {
    font-size: 15px;
}

textarea.edit-1823form.form-control {
    font-size: 13px;
}

.key-table td:nth-child(odd) {
    background-color: #ccc;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

.key-table td:nth-child(even) {
    background-color: black;
    color: white;
}

.key-table td {
    padding: 2px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
    font-weight: 400;
    border-radius: 5px;
}

select.resident.ambulance.form-control {
    font-size: 15px !important;
}

.signatureonfilee {
    color: #99C494 !important;
}

p.signatureonfilee {
    font-size: 17px !important;
}

.signature.form-group.mt-4 {
    width: auto !important;
}

.resident-avatar {
    width: 200px !important;
    text-align: center;
}

/* css for edit resident end */

/* css for add resident start */

button.resident-edit-examiner-btn.btn {
    background-color: #428bca;
    color: white;
    float: right;
    display: inline-block;
    padding: 6px 12px;
    font-size: 15px;
}

.form-group.sub-group {
    width: 100% !important;
    margin-bottom: 15px !important;
}

.col-sm-8 {
    padding: 0 !important;
}

.col-sm-3.resident-label-div {
    padding: 0 !important;
}

.form-group.sub-group input {
    width: 100% !important;
    color: #606060;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 34px;
}

.resident-label {
    display: inline-block;
    margin-bottom: 5px;
    line-height: 1.42857;
    font-size: 15px;
    color: #606060;
}

.input-section {
    width: 100% !important;
    color: #606060;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 34px;
}

.select-section {
    width: 100% !important;
    color: #606060;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 34px;
}

.resident-add-form {
    padding-top: 15px !important;
}

textarea {
    height: auto !important;
}

.sub-heading {
    color: #606060;
    margin-top: 15px;
}

.resident-add-form.row {
    padding: 4px 8px;
}

.bed-holds {
    color: #606060;
    font-size: 15px;
    margin-bottom: 20px;
}

.bed-holds thead {
    border-bottom: 2px solid #ccc;
}

.resident-save {
    display: flex;
}

button.btn.btn-success.resident-save:hover {
    font-size: 15px;
    padding: 5px 14px;
    background-color: #449d44 !important;
    border-color: #398439 !important;
    margin-right: 3px;
}

button.btn.btn-success.resident-save {
    font-size: 15px;
    padding: 5px 14px;
    background-color: #5cb85c !important;
    border-color: #4cae4c !important;
    margin-right: 3px;
}

button.btn.btn-warning.resident-cancel {
    color: white;
    background-color: #f0ad4e !important;
    border-color: #eea236 !important;
    font-size: 15px;
    padding: 5px 14px;
}

button.btn.btn-warning.resident-cancel:hover {
    color: white;
    background-color: #ec971f !important;
    border-color: #d58512 !important;
    font-size: 15px;
    padding: 5px 14px;
}

.examiner-resident.modal-header {
    background-color: white !important;
}

h4.examiner-invite-resident {
    color: dimgray !important;
    float: left;
}

button.resident-edit-examiner-btn.btn:active {
    background-color: #3071a9 !important;
    color: white !important;
    border-color: #285e8e !important;
}

button.resident-edit-examiner-btn.btn:hover {
    background-color: #3071a9 !important;
    color: white !important;
    border-color: #285e8e !important;
}

.error-message {
    padding: 10px;
    font-weight: bold;
    color: red;
}

.error-message p {
    font-size: 16px;
}

.add-resident-error-message {
    color: red;
    font-weight: bold !important;
    font-size: 12px !important;
}

.add-resident-error-input {
    border: 1px solid red !important;
}


/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Container */
.modal-section {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 600px;
    max-width: 90%;
    overflow: hidden;
    animation: fadeIn 0.3s ease-in-out;
}

/* Modal Header */
.modal-header {
    background-color: #007bff;
    /* Primary color */
    color: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h3 {
    margin: 0;
    font-size: 18px;
}

.modal-body b {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
}

.modal-body h3 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
}

.close-btn {
    background: none;
    border: none;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}

/* Modal Body */
.modal-body {
    padding: 15px;
    font-size: 14px;
    color: #333;
}

.modal-body input[type="file"] {
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

/* Modal Footer */
.modal-footer {
    padding: 10px 15px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    border-top: 1px solid #e0e0e0;
    margin: 10px;
}

/* Buttons */
.download-csv.btn {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.download-csv.btn-primary {
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s;
    margin: 10px;
}

.download-csv.btn-primary:hover {
    background-color: #0056b3;
}

.close-btn.btn-secondary {
    background-color: #e0e0e0;
    color: #333;
    transition: background-color 0.3s;
    font-size: 14px;
    padding: 4px 10px;
}

.close-btn.btn-secondary:hover {
    background-color: #bdbdbd;
    font-size: 14px;
    padding: 4px 10px;
    color: #292929;
}

.close-btn.btn-secondary:active {
    background-color: #bdbdbd !important;
    border-color: #bdbdbd !important;
    font-size: 14px;
    padding: 4px 10px;
    color: #292929 !important;
}

.upload-input {
    width: 100%;
    padding: 10px;
}

.upload-input input[type="file"] {
    width: 80%;
}

button.btn.btn-primary.download-csv:active {
    background-color: #0056b3 !important;
    border-color: #0056b3;
}

button.btn.btn-primary.upload-csv {
    padding: 4px 10px;
    font-size: 14px;
}

button.btn.btn-primary.upload-csv:hover {
    padding: 4px 10px;
    font-size: 14px;
    background-color: #0056b3 !important;
    border-color: #0056b3;
}

button.btn.btn-primary.upload-csv:active {
    padding: 4px 10px;
    font-size: 14px;
    background-color: #0056b3 !important;
    border-color: #0056b3;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


/* css for add resident start */


/* Target buttons within the specific parent container */
.content .btn-info:hover,
.content .btn-info:active {
    background-color: #00bbff !important;
    /* Override the background color */
    color: #fff !important;
    /* Ensure text color remains white */
    border-color: #00bbff !important;
    /* Override the border color */
    border: none !important;
    /* Remove any default border styles */
    box-shadow: none !important;
    /* Remove the inset shadow effect */
}


.content .btn-warning:hover {
    background-color: #d6a205;
    /* Set your desired hover color for warning button */
    color: #fff;
    /* Text color */
    border-color: #d39e00;
    /* Border color */
}

.content .btn-danger:hover {
    background-color: #c61515;
    /* Set your desired hover color for warning button */
    color: #fff;
    /* Text color */
    border-color: #d30000;
    /* Border color */
}



/* css for employeeView start */

.left-panel {
    position: relative;
}

/* Align the edit widgets to the top-right corner */
.left-panel .edit-widgets {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 5px;
    /* Optional: add spacing between icons */
}

.left-panel .edit-widgets a {
    color: #767676 !important;
    font-size: x-large;
    margin: 1rem;
}

.left-panel .edit-widgets a:hover {
    color: #007bff;
    /* Optional: Add hover color */
}

/* Targeting h5 inside a specific container */
.employee-view h5 {
    margin-left: -10px;
    font-size: 24px;
    margin-bottom: 10px;
    font-family: initial;
    font-weight: 500;
    line-height: 1.1;
    color: #767676;
    text-transform: uppercase;
}

.employee-view ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(101px, 1fr));
    gap: 10px;
    /* Space between the items */
    list-style-type: none;
    /* Remove bullet points */
    padding: 0;
    margin: 0;
    text-align: center;
}

.employee-view ul li {
    list-style: none;
    display: table;
    text-align: center;
    /* Center the text in each item */
    border-radius: 5px;
    /* Optional border radius */
    border-right: 1px solid #ececec;

}

.employee-view ul li p {
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.size-h3 {
    font-size: 3rem;
    margin: 0;
}

.color-danger {
    color: #d9534f;
}

.text-muted {
    color: #b3b3b3 !important;
    font-size: 1.9rem;
    margin-top: 0;
}

.list-container {
    padding-top: 1rem;
}

.cropper {
    background: #f3f3f3;
    width: 330px;
    padding: 20px 40px;
}

.cropper__readonly {
    padding: 20px;
    margin: 60px auto;
}

.employee-view .avatar {
    width: 100%;
    height: 100%;
    border-radius: 0%;
    max-height: 26rem;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

.sc-kuACkN .ebflIX {
    display: flex;
    border-bottom-width: 2px;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 500;
}

.employee-view h3 {
    padding: 10px 15px;
    font-weight: 600;
    font-size: large;
    color: #867f7f;
    border-bottom: 1px solid #d3d3d3;
}

/* css employee view end */


/* css for header resident and filter search start */

.resident-dropdown {
    position: absolute !important;
    color: black;
    background-color: white;
    overflow-y: scroll;
    padding-top: 6px;
    z-index: 1000;
    width: 262px;
}

input.w-full.p-2.border.rounded.mb-2:hover {
    cursor: pointer;
}

select.w-full.p-2.border.rounded.mb-2 {
    width: 220px;
    margin-right: 10px;
    height: 35px !important;
}

section.compliance.panel {
    width: 74.5%;
}

.no-heading {
    height: 160px;
}

.compliant-image img {
    width: 100%;
    margin-top: 35px;
    transform: rotate(10deg);
}

/* css for header resident and filter search end */

/* css for add employee start */

select.form-control.ng-valid.ng-dirty.ng-valid-parse.ng-touched {
    font-size: 1.5rem;
    color: #6e6d6d;
}

.optional {
    color: #606060;
    font-size: 10px;
}
.input-error {
    border: 1px solid red; /* Change border color to red */
}
span.btn.btn-position.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc !important;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.42857;
    border-radius: 4px;
}


span.btn.btn-position.col-md-12.btn-default {
    white-space: normal;
    text-align: left;
}

.groupname {
    font-size: 15px;
    color: #606060;
    font-weight: normal;
    padding-left: 10px;
}

.table.responsibility-table tbody tr td {
    color: #606060;
    font-size: 15px;
}

.panel-heading {
    padding: 10px;
    font-size: 15px;
    border-color: #eee;
    color: #606060;
}

p.text-info {
    color: #3B799A !important;
    font-size: 15px;
}

button#completed-task {
    background-color: #5cb85c;
    border-color: #5cb85c;
    font-size: 15px;
    padding: 5px 10px;
}

button#completed-task:hover {
    background-color: #5cb85c;
    border-color: #5cb85c;
    font-size: 15px;
    padding: 5px 10px;
}

button#completed-task:active {
    background-color: #5cb85c;
    border-color: #5cb85c;
    font-size: 15px;
    padding: 5px 10px;
}

p.section-heading {
    color: #606060;
    font-size: 15px;
}

.table.completed-task-table thead tr td {
    color: #606060;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
}

span.btn.btn-position.col-md-12.btn-default.active:hover {
    background-color: #3071a9 !important;
    border-color: #285e8e !important;
    color: white;
}

span.btn.btn-position.col-md-12.btn-default.active {
    background-color: #428bca !important;
    border-color: #357ebd !important;
}

span.btn.btn-position.col-md-12.btn-default:active {
    background-color: #428bca !important;
    border-color: #357ebd !important;
}

.Toastify__toast-theme--light {
    background: var(--toastify-color-light);
    color: var(--toastify-text-color-light);
    color: black !important;
    font-size: 14px !important;
    font-weight: bold !important;
}

/* css for add employee end */


/* css for Employee Description Start */

.toggle-view img {
    width: 24px;
    margin: 1rem .3rem;
}

.panel-body p {
    font-size: inherit;
}

.action-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.action-button.delete-button {
    background-color: red;
    color: white;
}

.action-button:hover {
    opacity: 0.8;
    background-color: rgb(151, 150, 150);
}

.card-title {
    text-transform: uppercase;
    font-weight: bold;

}

.file-grid {
    display: flex;
    flex-flow: row wrap;
    width: 90%;
    max-width: 1000px;
    margin: 10px auto;
}

.file-grid-certificates {
    width: 200px;
    margin: 20px;
}

.file-grid-certificates-certificate-image {
    width: 200px;
    height: 200px;
    background: #000;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

/* css Employee Description End */

/* css for add training event start */

table.table.training-event-add tbody tr td {
    border: none !important;
}

.time-div {
    width: 50px;
}

input.time-input {
    text-align: center;
    color: gray !important;
}

button.meridian {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    font-size: 14px;
    padding: 5px 10px;
}

button.btn.meridian:hover {
    color: #333;
    background-color: #e6e6e6 !important;
    border-color: #adadad;
}

button.btn.meridian:active {
    color: #333;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}

a.arrow-icon {
    color: #2a6496 !important;
}

td.employee-col {
    text-align: left !important;
}

td.expired {
    background-color: #de807d !important;
    color: white !important;
    font-weight: bold;
    font-size: 15px !important;
}

td.expired {
    background-color: #f2aa41;
    color: white;
    font-weight: bold;
    font-size: 15px !important;
}

.panel-heading.event {
    border-bottom: none;
}


a.glyphicon.glyphicon-pencil.widget-icon {
    color: #767676;
    font-size: x-large;
    padding: 4px 10px;
}

a.glyphicon.glyphicon-remove.widget-icon {
    color: #767676;
    font-size: x-large;
    padding: 4px 10px;
}

dl {
    font-size: 15px;
    color: #606060;
}

button.btn.btn-primary.btn-lg.print-flyer {
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    padding: 16px 18px;
    font-size: 19px;
    line-height: 1.33;
    border-radius: 6px;
    margin-top: 5px;
}

button.btn.btn-primary.btn-lg.print-flyer:hover {
    color: #fff;
    background-color: #3071a9;
    border-color: #285e8e;
    padding: 16px 18px;
    font-size: 19px;
    line-height: 1.33;
    border-radius: 6px;
    margin-top: 5px;
}

button.btn.btn-primary.btn-lg.print-flyer:active {
    color: #fff;
    background-color: #3071a9 !important;
    border-color: #285e8e !important;
    padding: 16px 18px;
    font-size: 19px;
    line-height: 1.33;
    border-radius: 6px;
    margin-top: 5px;
}


button.btn.btn-primary.btn-lg.end-event {
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    padding: 16px 18px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
    margin-top: 5px;
}

button.btn.btn-primary.btn-lg.end-event:hover {
    color: #fff;
    background-color: #3071a9;
    border-color: #285e8e;
    padding: 16px 18px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
    margin-top: 5px;
}

button.btn.btn-primary.btn-lg.end-event:active {
    color: #fff;
    background-color: #3071a9 !important;
    border-color: #285e8e !important;
    padding: 16px 18px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
    margin-top: 5px;
}

button.btn.btn-success.btn-lg.add-event {
    background-color: #5cb85c !important;
    border-color: #4cae4c !important;
    padding: 36px 48px;
    font-size: 19px;
    line-height: 1.33;
    border-radius: 6px;
}

button.btn.btn-success.btn-lg.add-event:hover {
    background-color: #449d44 !important;
    border-color: #398439 !important;
    padding: 36px 48px;
    font-size: 19px;
    line-height: 1.33;
    border-radius: 6px;
}

button.btn.btn-success.btn-lg.add-event:active {
    background-color: #449d44 !important;
    border-color: #398439 !important;
    padding: 36px 48px;
    font-size: 19px;
    line-height: 1.33;
    border-radius: 6px;
}

.table-filters {
    margin-top: 10px;
    margin-left: 10px;
}

.total-count {
    font-size: 15px;
}

td.table-header {
    display: flex;
    justify-content: space-between;
}

/* css for add training event end */


/* css for facility details start */

small.facility-details-error-message.text-danger.d-block.mt-1 {
    font-weight: bold !important;
    font-size: 12px !important;
}


input.edit-1823form.form-control.readonly {
    border: none !important;
    background: transparent !important;
}

section.facility-nav-section {
    font-size: 19px !important;
}

.facility-details-form .form-group {
    display: flex;
    width: 100% !important;
    margin-bottom: 15px;
}

.facility-details-form .form-control {
    width: 100%;
    box-sizing: border-box;
}

.facility-details-form.facility-detail-label {
    display: inline-block;
    margin-bottom: 0;
    width: auto;
    padding-right: 10px;
    flex: 0 0 30%;
}

select.facility-detail-state.form-control {
    font-size: 15px !important;
}

input.edit-1823form.form-control {
    font-size: 15px;
    font-weight: 400;
}

label.facility-detail-label {
    margin-right: 12px;
}

input.facility_email_add.form-control {
    font-size: 14px;
}

button.facilitydetails.btn.btn-primary.mt-2 {
    font-size: 15px !important;
}

.facility-details-form.row {
    margin-left: 16px;
    margin-top: 14px !important;
    margin-bottom: 21px !important;
}

/*   css for facility details end */

/* css for admission log start */

a.admisionlog.btn.btn-info {
    font-size: 15px !important;
    color: white;
}

select.admissionlog.form-control {
    font-size: 15px !important;
}

.admissionlog.col-md-4.mb-3 {
    font-size: 20px;
}

input.admissionlog.form-control {
    font-size: 16px;
}

/* css for admission log end */

/* css for subscription-staff start */

.subscription-content {
    min-height: 100vh;
    width: 100%;
    color: #000;
    margin-bottom: 0;
    background-size: cover !important;
    padding: 10px;
}
.subscription-contents {
    min-height: 100vh;
    width: 100%;
    color: #000;
    margin-bottom: 0;
    background-size: cover !important;
    padding: 10px;
}

.subscription-heading {
    margin-top: 200px;
}

.col-md-5.plans {
    border-radius: 5px;
    border: 1px #7A9CB6 solid;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 5px;
}

.plan-details {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-size: 1.5em;
}

.plan-amount {
    font-size: 3em;
    color: #000;
}

.description.best-deal {
    background-color: #008D5E;
    width: 100%;
    font-size: 2.2em;
    text-align: center;
    border-radius: 5px 5px 0 0;
    color: #FFF;
}

.disclaimer {
    font-size: 1.5em;
    text-align: center;
}

.plans {
    margin-bottom: 30px;
}

button.btn.btn-info.btn-lg.col-xs-4.col-xs-offset-4 {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
    padding: 36px 48px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    margin-left: 33.33333%;
    width: 33.33333%;
}

/* css for subscription-staff end */

/* css for tutorials video start */

.tutorials__grid {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.tutorials__grid__box {
    flex: 1;
    padding: 20px;
    margin-bottom: 10px;
}

.tutorials__grid__box img {
    width: 400px;
}

p.tutorial-title {
    font-size: 15px;
    line-height: 1.42857;
    color: #606060;
}

.tutorials__grid__box__overlay__button {
    z-index: 100;
    font-size: 16px;
    padding: 18px 27px;
    text-align: center;
    vertical-align: middle;
    color: #666;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    background-color: #EBEBEB;
}

.tutorials__grid__box__overlay {
    margin-top: -150px;
    margin-bottom: 130px;
}

/*  css for tutorials video end */
/* css for Add Custom Trainings Start */
.panel-body .form-groups {
    width: auto !important;
    font-size: 14px;
    line-height: 1.42857;
    color: #606060;
    font-family: "Lato", Helvetica Neue, Calibri, Helvetica, Arial, sans-serif;
    margin-bottom: 15px;
}


/* css for Add Custom Trainings End */

/* css for add task-type start */

.row.task-row {
    margin-top: 1rem;
}

.eventheading {
    text-transform: uppercase;
    font-size: 24px;
}

li.required-for {
    list-style: none;
    font-size: 15px;
    color: #606060;
}

span.btn.btn-position.btn-default.active:hover {
    background-color: #3071a9 !important;
    border-color: #285e8e !important;
    color: white;
}

span.btn.btn-position.btn-default.active {
    background-color: #428bca !important;
    border-color: #357ebd !important;
    color: white;
}

span.btn.btn-position.btn-default:active {
    background-color: #428bca !important;
    border-color: #357ebd !important;
    color: white;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    /* Ensure it appears above other content */
}

/* Modal Dialog */
.modal-dialog.delete {
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    /* Default width */
    max-width: 500px;
    /* Maximum width */
}

.modal-header.ng-scope.delete {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.42857px;
    color: gray;
}

.modal-content {
    background-color: #fff !important;
    border-radius: 10px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-header.ng-scope {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.42857px;
    color: gray;
    background-color: white !important;
    font-size: 20px !important;
}

.modal-body {
    padding: 15px !important;
    font-size: 14px;
    color: #333;
}

p.ng-binding {
    font-size: 15px !important;
}

button.btn.btn-primary.yes {
    background-color: #428bca;
    border-color: #357ebd;
    color: white;
    padding: 7px 13px;
    font-size: 15px;
}

button.btn.btn-primary.yes:hover {
    background-color: #3071a9 !important;
    border-color: #285e8e !important;
    color: white;
    padding: 7px 13px;
    font-size: 15px;
}

button.btn.btn-primary.yes:active {
    background-color: #3071a9 !important;
    border-color: #285e8e !important;
    color: white;
    padding: 7px 13px;
    font-size: 15px;
}

button.btn.btn-warning.no {
    background-color: #f0ad4e;
    border-color: #eea236;
    color: white;
    padding: 7px 13px;
    font-size: 15px;
}

button.btn.btn-warning.no:hover {
    background-color: #ec971f !important;
    border-color: #d58512 !important;
    color: white;
    padding: 7px 13px;
    font-size: 15px;
}

button.btn.btn-warning.no:active {
    background-color: #ec971f !important;
    border-color: #d58512 !important;
    color: white;
    padding: 7px 13px;
    font-size: 15px;
}

@media (max-width: 576px) {
    .modal-content {
        width: 100%;
        /* Full width on small screens */
    }

         
    .invite-col-6.col-xs-6 {
        width: 100% !important;
    }
}

tbody tr td a {
    color: #606060;
}

/* css for add task-type end */



/* css for subscription modal start */

.modal-dialog.subscribe {
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    /* Default width */
    max-width: 80%;
    /* Maximum width */
}

.modal-content.subscribe {
    background-color: #fff !important;
    border-radius: 10px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    height: 400px;
}

.subscription-modal-content-wrap.ng-scope {
    border-radius: 8px;
    height: 100%;
    background-size: cover;
}

.subscription-modal .modal-dialog .subscription-modal-content {
    color: #586e6e;
    height: 100%;
}

.subscription-modal-content-header.row {
    width: 40%;
    float: right;
    text-align: center;
    margin-right: 1rem;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #586e6e;
}

h2.subscribe-h2 {
    font-size: 30px;
}

h4.subscribe-h4 {
    font-size: 20px;
}

img.subscription-modal-content-logo {
    width: 7rem;
    height: auto;
    position: absolute;
    bottom: 30px;
    right: 31px;
}

button.btn.btn-success.subscribe-btn {
    width: 50%;
}

button.btn.btn-primary.mt-2.add-email:active {
    background-color: #0a58ca !important;
    border-color: #0a53be !important;
    font-size: 14px;
}

button.btn.btn-primary.mt-2.add-email {
    background-color: #327eef !important;
    border-color: #2270e5 !important;
    font-size: 14px;
}

button.btn.btn-primary.mt-2.add-email:hover {
    background-color: #0a58ca !important;
    border-color: #0a53be !important;
    font-size: 14px;
}

button.btn.btn-danger.remove-email {
    font-size: 14px;
    border-color: #b01626 !important;
}

button.btn.btn-danger.remove-email:hover {
    font-size: 14px;
    background-color: #ac0909 !important;
    border-color: #650811 !important;
}

button.btn.btn-danger.remove-email:active {
    font-size: 14px;
    background-color: #ac0909 !important;
    border-color: #650811 !important;
}

button.btn.btn-success.subscribe-btn {
    width: 50%;
    font-size: 15px;
    background-color: #5cb85c;
    border-color: #4cae4c !important;
    border-radius: 5px;
}

button.btn.btn-success.subscribe-btn:hover {
    width: 50%;
    font-size: 15px;
    background-color: #449d44 !important;
    border-color: #398439 !important;
    border-radius: 5px;
}

button.btn.btn-success.subscribe-btn:active {
    width: 50%;
    font-size: 15px;
    background-color: #449d44 !important;
    border-color: #398439 !important;
    border-radius: 5px;
}

@media (min-width: 600px) {
    .subscription-plans-plan {
        width: 46%;
        display: inline-block;
        margin: .5rem 1%;
    }
}

.subscription-plans-plan-details {
    margin-left: 2rem;
}

.subscription-plans-plan {
    border: 3px solid #586e6e;
    border-radius: 1rem;
    margin: .5rem 1%;
    padding: 1.5rem;
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 1.5rem;
    text-align: center;
}

@media (min-width: 1200px) {
    .subscription-plans-plan-header {
        font-size: 3.5rem;
    }
}

@media (min-width: 1200px) {
    .subscription-plans-plan-param {
        font-size: 2rem;
    }
}

.subscription-plans-frequencies {
    width: 80%;
    margin: 0 auto;
    text-align: left;
}


button.btn.btn-info.btn-lg.col-xs-offset-4 {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
    padding: 36px 48px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    margin-left: 33.33333%;
}

.business-agreement {
    height: 100%;
}

.business-agreement__header {
    margin-left: 2rem;
    height: 30px;
    font-size: 24px;
}

.business-agreement__pdf {
    width: 100%;
    height: calc(100% - 30px - 200px);
}

.business-agreement__footer {
    margin: 0 3rem;
    height: 200px;
}

.business-agreement__footer__signature {
    padding: .5rem 2rem;
}

.business-agreement__footer__signature canvas {
    border: 1px solid black;
    width: 400px;
    height: 100px;
}

.business-agreement__footer__buttons {
    padding: 2rem;
}

button.btn.btn-md.btn-success.business-agreement__footer__buttons__button {
    background-color: #5cb85c;
    border-color: #4cae4c;
    color: white;
    margin: 1rem;
}

button.btn.btn-md.btn-success.business-agreement__footer__buttons__button:hover {
    background-color: #46a446;
    border-color: #41a041;
    color: white;
    margin: 1rem;
}

button.btn.btn-md.btn-success.business-agreement__footer__buttons__button:active {
    background-color: #46a446;
    border-color: #41a041;
    color: white;
    margin: 1rem;
}

button.btn.btn-md.btn-danger.business-agreement__footer__buttons__button {
    color: white;
    background-color: #d9534f !important;
    border-color: #d43f3a !important;
    margin: 1rem;
}

button.btn.btn-md.btn-danger.business-agreement__footer__buttons__button:hover {
    color: white;
    background-color: #c61515 !important;
    border-color: #d30000 !important;
    margin: 1rem;
}

button.btn.btn-md.btn-danger.business-agreement__footer__buttons__button:active {
    color: white;
    background-color: #c61515 !important;
    border-color: #d30000 !important;
    margin: 1rem;
}

.sign-div {
    font-size: 15px;
}

/* css for subscription modal end */



/*css for invite training user start*/
button.invites-select.btn.btn-primary {
    font-size: 15px !important;
}

button.linkuser.btn.btn-primary {
    font-size: 14px !important;
}

select.managerole.form-control {
    font-size: 14px !important;
}

input.managerrole.form-control.search-input {
    font-size: 14px !important;
}

/*css for invite training user end */


/* css for trial subscription page start */

.subscription-h1 {
    font-size: 29px;
}
.cta.trial {
    margin-top: 200px;
}
button.btn.btn-info.btn-lg.trial {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
    padding: 36px 48px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
}


/* css for trial subscription staff end */

/* css for faciltiy subscription start */
.btn.btn-primary.upgrade {
    color: white !important;
    background-color: #428bca;
    border-color: #357ebd;
    font-size: 15px;
}

button.btn.btn-primary.upgrade {
    color: white;
    background-color: #428bca;
    border-color: #357ebd;
    font-size: 15px;
}

button.btn.btn-primary.upgrade:hover {
    color: white;
    background-color: #3071a9;
    border-color: #285e8e;
    font-size: 15px;
}

button.btn.btn-primary.upgrade:active {
    color: white;
    background-color: #3071a9 !important;
    border-color: #285e8e !important;
    font-size: 15px;
}

button.btn.btn-danger.cancel:active {
    background-color: #c61515 !important;
    border-color: #d30000 !important;
    color: white;
    font-size: 15px;
}

button.btn.btn-danger.cancel:hover {
    background-color: #c61515;
    border-color: #d30000;
    color: white;
    font-size: 15px;
}

button.btn.btn-danger.cancel {
    color: white;
    font-size: 15px;
}

button.btn.btn-default.print {
    background-color: #fff;
    font-size: 14px;
    border-color: #ccc;
    color: #333;
}

button.btn.btn-default.print:hover {
    background-color: #e6e6e6;
    font-size: 14px;
    border-color: #adadad;
    color: #333;
}

button.btn.active-status {
    font-size: 12px;
    background-color: #5cb85c;
    border-color: #47a247;
    color: white;
    font-weight: 500;
}

button.btn.trailing-status {
    font-size: 12px;
    background-color: #1c7abd;
    border-color: #1c7abd;
    color: white;
    font-weight: 500;
}

button.btn.canceled-status {
    font-size: 12px;
    background-color: rgb(216, 25, 25);
    border-color: rgb(199, 7, 7);
    color: white;
    font-weight: 500;
}

button.btn.pending-cancel-status {
    font-size: 12px;
    background-color: rgba(25, 184, 216, 0.721);
    border-color: rgba(14, 153, 181, 0.721);
    color: white;
    font-weight: 500;
}

button.btn.past-due-status {
    font-size: 12px;
    background-color: rgba(25, 184, 216, 0.721);
    border-color: rgba(14, 153, 181, 0.721);
    color: white;
    font-weight: 500;
}

/* css for facility subscription end */

/* css for awaitng modal start*/

.modal-header.awiating {
    background-color: #fff;
    color: #606060;
}

.modal-dialog.awaiting {
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 600px;
}

.modal-content.awaiting {
    background-color: #fff !important;
    border-radius: 10px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.staff-plans {
    width: 80%;
    margin: 7% auto 2%;
    padding: 4% 5%;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    overflow: hidden;
}

.subscription-plans-plan-header p span {
    color: #586e6e;
    font-size: 39px;
}

.subscription-plans-plan-param p span {
    color: #586e6e;
    font-size: 23px;
}

.subscription-plans-frequencies p {
    color: #586e6e;
    font-size: 18px;
}

label.subscription-plans-frequency-name {
    color: #586e6e;
    font-size: 16px;
}

/* css for awaiting modal end */

/* announcement subscription start */

h3.title {
    font-size: 78px;
    margin-top: 24px;
    margin-bottom: 41px;
    line-height: 1;
    color: #1B3D57;
    text-align: center;
    font-weight: bold;
}

p.announcement-text {
    font-size: 31px;
    color: #1B3D57;
    padding-left: 33px;
    padding-right: 30px;
    font-weight: 500;
}

.content-container.sponsorship {
    display: flex;
    flex-direction: row;
    margin-top: 74px;
    justify-content: flex-end;
}

p.sponsor-text {
    color: #1B3D57;
    font-size: 46px;
    text-align: center;
}

img.sponsorship-modal-content-logo {
    width: 7rem;
    height: auto;
    top: 234px;
    right: 31px;
}

button.btn.btn-info.btn-subscribe.green {
    background-color: #02A651;
    width: 100%;
    height: 83px;
    font-size: 2.3rem;
    color: white;
}

button.btn.btn-info.btn-subscribe.green:hover {
    background-color: #02A651 !important;
    height: 83px;
    font-size: 2.3rem;
    color: white;
}

button.btn.btn-info.btn-subscribe.green:active {
    background-color: #02A651 !important;
    height: 83px;
    font-size: 2.3rem;
    color: white;
}

button.btn.btn-info.btn-subscribe {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
    width: 100%;
    font-size: 2.3rem;
    height: 83px;
}

button.btn.btn-info.btn-subscribe:hover {
    color: #fff;
    background-color: #31b0d5 !important;
    border-color: #269abc !important;
    width: 100%;
    font-size: 2.3rem;
    height: 83px;
}

button.btn.btn-info.btn-subscribe:active {
    color: #fff;
    background-color: #31b0d5 !important;
    border-color: #269abc !important;
    width: 100%;
    font-size: 2.3rem;
    height: 83px;
}

.subscribe-now {
    margin-top: 10px;
    text-align: initial;
    display: flex;
    justify-self: center;
    justify-content: center;
}

img.trainings-logo {
    position: absolute;
    top: 771px;
    right: 34px;
    display: block;
    width: 70px !important;
    margin: 0 auto;
}



/* announcement subscription end */

/* css for course detail page start */

section.main-container.tasks.panel {
    background-color: #69788B;
    padding: 20px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.course.panel-heading {
    padding: 0;
    margin: 10px;
    border-radius: 0px;
    }

.col-md-6.panel-heading-actions.text-center strong {
    color: white;
    font-weight: bold;
    font-size: 20px;
}

.col-md-3.panel-heading-actions.text-right strong {
    color: white;
    font-weight: bold;
    font-size: 20px;
}

.text-center {
    margin-bottom: 0 !important;
}

.course.panel-body {
    background-color: white !important;
    padding: 0;
}

.row.heading-content {
    padding: 6px;
}

.training-logo {
    width: 87px;
}

.course-image {
    object-fit: fill;
    height: 44.1rem  !important;
}

.jss {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.course-name strong {
    color: black !important;
    font-size: 25px !important;
    font-weight: bold !important;
}

.course-data p {
    font-size: 16px;
    font-weight: bold;
}

.row.course-name {
    padding: 30px;
}

span.button-label p {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0;
}

button.course-item {
    margin-bottom: 12px;
}

.box-shadow-bottom {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
    padding-bottom: 20px; /* Optional: Add some padding to the bottom */
}

.course-names {
    margin-bottom: 20px;
}

.course-name {
    margin-bottom: 20px;
    padding: 5px;
}

.start-course {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
}

button.course-button {
    border: 0;
    flex: 0 0 auto;
    color: white !important;
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    /* background-color: #0ce817 !important; */
}
button.course-button:hover {
    background-color: #c5c1c1;
}

span.text {
    color: #1973E8;
    font-size: 16px;
    font-weight: bold;
}

.course-items {
    padding: 13px;
    border-radius: 3px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

button.course-item {
    border: 0;
    background-color: transparent;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-transform: uppercase;
    cursor: not-allowed !important;
}


span.button-label {
    display: flex;
}

img.item-image {
    margin-right: 12px;
}

p.info-text {
    margin-top: 19px;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
}

.app-store {
    width: 100px;
    margin-left: 10px;
}

.play-store {
    width: 100px;
    margin-left: 10px;
}

.jss31 {
    width: 100%;
    text-align: center;
    margin-top: 10px;
}


/* css for course details page end */

/* css for mcq questions start  */
.mcq-content {
    padding: 20px;
}

.mcq-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.mcq-loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mcq-main-container {
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 32px;
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-bottom: 39px;
}

.mcq-panel-heading {
    margin-bottom: 24px;
}

.mcq-heading-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mcq-panel-heading-title img {
    max-width: 100px;
}

.mcq-panel-heading-actions {
    display: flex;
    align-items: center;
}

.mcq-text-center {
    text-align: center;
}

.mcq-text-right {
    text-align: right;
}

.mcq-panel-body {
    padding: 20px;
}

.mcq-course-name {
    margin-bottom: 16px;
}

.mcq-course-item-name {
    font-size: 24px;
    font-weight: bold;
}

.mcq-course-data {
    font-size: 16px;
    color: #6b7280;
}

.mcq-flex {
    display: flex;
}

.mcq-justify-center {
    justify-content: center;
}

.mcq-items-center {
    align-items: center;
}

.mcq-question-section {
    width: 100%;
}

.mcq-question-text {
    font-size: 18px;
    font-weight: 500;
    color: #1f2937;
    margin-bottom: 16px;
}

.mcq-options {
    margin-bottom: 16px;
}

.mcq-option {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.mcq-option-label {
    font-size: 16px;
    font-weight: 500;
    color: #1f2937;
    margin-right: 8px;
}

.mcq-option-text {
    font-size: 16px;
    color: #374151;
}

.mcq-mr-2 {
    margin-right: 8px;
}

.mcq-p-4 {
    padding: 16px;
}

.mcq-next-button {
    background-color: #d1d5db;
    color: #4b5563;
    font-weight: 600;
    padding: 8px 24px;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
}
/* css for mcq questions end  */

.col-md-6.course-details {
    background-color: #1A1A1A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 7.4rem 5.6rem 11.9rem;
    min-height: 44.1rem;
}

.cover__header-content-info {
    max-width: 38.4rem;
    margin: 0;
    text-align: center;
}

p.course-names {
    color: white;
    font-size: 26px;
}

span.text {
    color: black !important;
    font-size: 18px;
    font-weight: bold;
}

button.course-button {
    background-color: white;
    border: .2rem solid transparent;
    border-radius: 10rem;
    color: var(--color-cover-cta-text);
    cursor: pointer;
    padding: 0.1rem 4.8rem;
    text-align: center;
    text-decoration: none;
    transition: transform .15s ease-in-out;
    white-space: nowrap;
}

.col-md-6.course-image-section {
    min-height: 44rem;
    padding: 0;
}

.logo-image {
    width: 120px;
}

.col-md-6.course-items-section {
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.course-items-list {
    margin-top: 20px;
}

.logo {
    margin-bottom: 10px;
}

/* css for course details page end */

/* css for course text item page start*/

strong.course-item-name {
    color: #4c5a6d !important;
    font-size: 32px !important;
}


.jss2 {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.row.items-data {
    padding: 30px;
}

.question-section {
    padding: 16px;
    word-break: break-word;
    white-space: pre-wrap;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

.question-value {
    color: black;
    font-size: 1.7rem;
}

.next-button {
    margin: auto;
    padding-top: 20px;
}

.row.course.panel-heading {
    padding: 0;
    border-radius: 0px;
    margin-top: 0 !important;
}

.course-item-list {
    overflow-y: scroll;
    height: 60vh;
    padding-top: 10px;
    padding-bottom: 10px;
}

.course-sidebar-heading {
    background-color: #047E62 !important;
    color: white !important;
    padding: 25px;
    display: flex;
}

.col-md-3.course-sidebar {
    padding: 0;
}

.sidebar-course-item-list {
    overflow-y: scroll;
    height: 100vh;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.course-item.panel-body {
    padding: 0;
}

i.fa-solid.fa-bars.course-menu {
    font-size: 20px;
}

button.exit-button.btn.btn-primary {
    color: white;
    background-color: gray;
    font-size: 16px;
    text-align: right;
    border: gray;
    font-weight: bold;
}

button.exit-button.btn.btn-primary:hover {
    color: white;
    background-color: #676666 !important;
    font-size: 16px;
    text-align: right;
    border: gray;
    font-weight: bold;
}

.question-section {
    background-color: #F6FAFC;
}

button.exit-button.btn.btn-primary:active {
    color: white;
    background-color: #676666 !important;
    font-size: 16px;
    text-align: right;
    border: gray;
    font-weight: bold;
}

i.fa-solid.fa-bars-staggered.item-bars {
    font-size: 14px;
    padding-top: 4px;
    margin-right: 10px;
}

button.course-item {
    display: flex
;
    justify-content: space-between;
}

/* css for course text item page end */

/* animated buttons css start */

.animated-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px 36px;
    border: 4px solid;
    border-color: transparent;
    font-size: 16px;
    background-color: inherit;
    border-radius: 100px;
    font-weight: 600;
    color: gray;
    box-shadow: 0 0 0 2px gray;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button svg {
    position: absolute;
    width: 24px;
    fill: gray;
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .animated-button svg.arr-2 {
    fill: white !important;
}
  
  .animated-button .arr-1 {
    right: 16px;
  }
  
  .animated-button .arr-2 {
    left: -25%;
  }
  
  .animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: gray;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .button-text {
    position: relative;
    z-index: 1;
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
    color: #212121;
    border-radius: 12px;
  }
  
  .animated-button:hover .arr-1 {
    right: -25%;
  }
  
  .animated-button:hover .arr-2 {
    left: 12px;
  }
  
  .animated-button:hover .text {
    transform: translateX(12px);
  }
  
  .animated-button:hover svg {
    fill: gray;
  }
  
  .animated-button:active {
    scale: 0.95;
    box-shadow: 0 0 0 4px gray;
  }
  
  .animated-button:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
  
  .animated-button span.button-text {
    color: gray;
    padding-left: 30px;
    padding-right: 30px;
  }
  .animated-button span.button-text:hover {
    padding-right: 30px;
    padding-left: 30px;
    color: white;
    }
    .animated-button {
        color: gray;
        padding-left: 30px;
        padding-right: 30px;
      }
      .animated-button:hover {
        padding-right: 30px;
        padding-left: 30px;
        color: white;
        }

/* animated buttons css end */

/* css for course header start */

.course-header-section {
    background-color: #1A1A1A;
    color: white;
    font-size: 25px;
    padding: 5px 13px;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

strong.header-item {
    font-size: 17px;
    margin-left: 64px;
    cursor: pointer;
}

/* css for course header end */

/* css for course video start */

.checkbox-container {
    position: relative;
    display: inline-block;
}

.complete-item {
    display: none; /* Hide the default checkbox */
}

.circular-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #606060;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.circular-checkbox::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px; /* Inner circle size */
    height: 12px; /* Inner circle size */
    background-color: #047E62 !important; /* Inner circle color */
    border-radius: 50%; /* Make it circular */
    transform: translate(-50%, -50%); /* Center the inner circle */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s; /* Smooth transition */
}

/* Show the inner circle when the checkbox is checked */
.complete-item:checked + .circular-checkbox::after {
    opacity: 1; /* Show the inner circle */
}

.question-value.video {
    position: relative; /* Set position to relative for absolute positioning of the overlay */
    width: 50%;
    margin: auto;
}

img.video-thumbnail {
    width: 100%;
    display: block; /* Ensure no extra space below the image */
}

.videos__grid__box__overlay {
    position: absolute; /* Position the overlay absolutely */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for the size of the overlay */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%; /* Full width of the parent */
    height: 100%; /* Full height of the parent */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent background */
    opacity: 1; /* Always show the overlay */
    transition: opacity 0.3s; /* Smooth transition for visibility */
}

.videos__grid__box__overlay__button {
    z-index: 100;
    font-size: 90px;
    padding: 18px 27px;
    text-align: center;
    vertical-align: middle;
    color: #ffffff; /* Change color to white for better visibility */
    border: 0;
    background-color: transparent;
}

.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
}

.video-modal-content {
    position: relative;
    width: 60%;
    max-width: 800px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
}

.close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 54px;
    cursor: pointer;
    color: #47f8be;
    font-weight: bold;
}

.close:hover {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 54px;
    cursor: pointer;
    color: #47f8be;
    font-weight: bold;
}

.close:active {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 54px;
    cursor: pointer;
    color: #47f8be;
    font-weight: bold;
}

/* css for coirse video end */

.circular-checkbox {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid gray;
    background-color: transparent;
    transition: background-color 0.3s ease, border 0.3s ease;
}

/* Ensure `.checked` applies correctly */
.circular-checkbox.checked {
    background-color: gray !important; /* ✅ Ensures it's gray */
    border: 2px solid gray !important; /* ✅ Makes sure border is also gray */
}




/* css for course signature page */
.course-name {
    color: black; /* Set the text color to black */
}
.sig-flex {
    display: flex;
    justify-content: center; /* Centers the buttons horizontally */
    align-items: center; /* Ensures the buttons are vertically aligned */
    gap: 20px; /* Adds space between the buttons */
    padding: 16px 0;
    margin-left: 295px; /* Padding around the button container */
}

.sig-clear-button, .sig-save-button {
    padding: 12px 24px; /* Adds padding inside the buttons */
    font-size: 16px; /* Adjusts the font size */
    border: none;
    border-radius: 8px; /* Rounded corners for buttons */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Clear Button */
.sig-clear-button {
    background-color: #f44336; /* Red color for 'Clear' */
    color: #fff;
}

.sig-clear-button:hover {
    background-color: #d32f2f; /* Darker red on hover */
    transform: scale(1.05); /* Slight scale effect */
}

/* Save Button */
.sig-save-button {
    background-color: #4caf50; /* Green color for 'Save' */
    color: #fff;
}

.sig-save-button:hover {
    background-color: #388e3c; /* Darker green on hover */
    transform: scale(1.05); /* Slight scale effect */
}
.sig-signature-box {
    border: 1px solid #000; /* Black border to make it visible */
    background-color: #fff; /* White background */
    cursor: crosshair; /* Show crosshair on canvas */
}


/* css for course signature page end */


/* css for summary page start */

.success-image {
    background-color: #f5f5f5;
    width: 20%;
    margin: auto;
}

.congratulation-image {
    width: 100%;
}

.course-status {
    margin: auto;
    width: 40%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.course-status h2 {
    font-size: 40px;
    font-weight: bold;
    padding: 10px 32px;
    text-align: center;
}

.course-summary {
    width: 50%;
    margin: auto;
}

.course-summary h2 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.course-summary p {
    font-size: 30px;
    text-align: center;
}


.question-text {
    font-size: 20px;
    font-weight: bold;
}

/* css for summary page end */

/* css for mcq page start */

.mcq-option input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid black;
    outline: none;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
}


.mcq-option input[type="checkbox"]:checked {
    background-color: #047E62 !important;
}


.mcq-option input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.mcq-option {
    font-size: 15px;
    display: flex;
    align-items: center;
}

.letter-image {
    width: 60%;
    margin: auto;
}

/* css for mcq page end */

/* css for course sidebar */
#course-nav {
    transition: width 0.3s ease; /* Smooth transition for width */
}

#course-nav.nav-collapsed-min.col-md-3.course-sidebar {
    width: 5%; /* Example collapsed width */
}

@media (min-width: 768px) {
    #course-nav.nav-collapsed-min {
        padding: 15px;
        text-align: center;
    }

    #course-nav.nav-collapsed-min .sidebar-course-item-list p {
        display: none; /* Hide text in buttons */
    }

    #course-nav.nav-collapsed-min .sidebar-course-item-list i {
        display: block; /* Show only icons */
    }
}

div#course-nav {
    padding: 0 !important;
}

h2.coursename {
    margin-right: 10%;
}


/*css for making scroll black */
.custom-scrollbar .ps__rail-x,
.custom-scrollbar .ps__rail-y {
  background-color: transparent !important; /* Transparent background */
}

.custom-scrollbar .ps__thumb-x,
.custom-scrollbar .ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.8) !important; /* Darker transparent black */
}
/*css for making scroll black end */

/* css for bed holds start */

table.table.completed-task-table td {
    text-align: unset;
}

table.table.bed-holds td {
    text-align: inherit;
}

button.btn.btn-success.bed-hold-edit {
    background-color: #5cb85c !important;
    border-color: #4cae4c;
    color: white;
}

button.btn.btn-success.bed-hold-edit:hover {
    background-color: #449d44 !important;
    border-color: #398439 !important;
    color: white;
}

button.btn.btn-success.bed-hold-edit:active {
    background-color: #449d44 !important;
    border-color: #398439 !important;
    color: white;
}

button.btn.btn-danger.bed-hold-delete {
    color: white;
    margin-left: 2px;
}

button.btn.btn-danger.bed-hold-delete:active {
    background-color: #c9302c !important;
    border-color: #ac2925 !important;
    color: white;
    margin-left: 2px;
}

/* css for bed hold end */


.boolean-options {
    padding: 20px;
    align-items: center;
    display: flex;
    justify-content: space-around;
}

.mcq-next-button {
    background-color: #047E62 !important;
    color: white;
    font-weight: 600;
    padding: 10px 75px;
    border: none;
    border-radius: 9999px;
    margin-bottom: 8px;
}

/* css for password reset start */

.col.col-md-5.signin-logo img {
    float: right;
}

.successMessage {
    font-size: 16px;
    color: #687F42;
}

/* css for password reset end */



table.table.attendee td {
    text-align: left;
}

/* main header css start */

.main-header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* main header css end */

/* css for update card details */
/* Custom Modal Overlay */
.custom-modal-overlay {
    position: fixed; /* Full screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Box */
  .custom-modal {
    background: white;
    width: 400px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  /* Close Button */
  .close-btn {
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: red;
  }
  
  /* Modal Body */
  .modal-body {
    margin-top: 15px;
  }
  
  /* Modal Footer */
  .modal-footer {
    text-align: right;
    margin-top: 15px;
  }
  
  /* Close Button */
  .close-modal-btn {
    background: gray;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-modal-btn:hover {
    background: darkgray;
  }
  
  /* Open Modal Button */
  .open-modal-btn {
    background: blue;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .open-modal-btn:hover {
    background: darkblue;
  }
  
  /* Fade In Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .card-update-form {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between elements */
    max-width: 400px; /* Set a max width for the form */
    margin: auto; /* Center the form */
}

.card-update-form label {
    font-weight: bold;
    margin-bottom: 5px; /* Space between label and input */
}

.card-update-form .StripeElement {
    padding: 10px; /* Padding inside the card input */
    border: 1px solid #ccc; /* Border color */
    border-radius: 4px; /* Rounded corners */
    transition: border-color 0.2s; /* Smooth transition for border color */
}

.card-update-form .StripeElement:focus {
    border-color: #007bff; /* Change border color on focus */
}

.card-update-form button {
    padding: 10px; /* Padding for the button */
   
}
button.cardclose.btn.btn-danger {
    font-size: 14px;
}

button.cardclosecross {
    margin-right: -63px;
    color: red;
}
.cardheader.modal-header {
    background-color: white !important;
    color: gray;
}
 /* css for update card details end */ 

/* css for resident modules start*/

button.resident-list-exam.btn.btn-primary {
    display: block;
    margin: 0 auto;
    background-color: transparent;
    color: #428bca !important;
    border: 2px #428bca solid !important;
}
button.resident-list-exam.btn.btn-primary:hover {
    background-color: #428bca !important;
    color: white !important;
}
button.resident-list-exam.btn.btn-primary:active {
    background-color: #428bca !important;
    color: white !important;
}

.content .btn-warning {
    background-color: #31b0d5 !important;
    color: #fff !important;
    border-color: #31b0d5 !important;
    font-size: 12px;
}
.content .btn-warning:hover {
    background-color: #0c88ac !important;
    color: #fff !important;
    border-color: #0c88ac !important;
    font-size: 12px;
}
.content .btn-warning:active {
    background-color: #0c88ac !important;
    color: #fff !important;
    border-color: #0c88ac !important;
    font-size: 12px;
}

.content .btn-danger {
    background-color: #d72b2b !important;
    color: #fff !important;
    border-color: #d72b2b !important;
    font-size: 12px !important;
}

.content .btn-danger:hover {
    background-color: #c61515 !important;
    color: #fff !important;
    border-color: #d30000 !important;
    font-size: 12px !important;
}

.content .btn-danger:active {
    background-color: #c61515 !important;
    color: #fff !important;
    border-color: #d30000 !important;
    font-size: 12px !important;
}

button.btn.btn-danger.cancel {
    color: white;
    font-size: 15px !important;
}
/* css for resident module end */


/* css for upgrade subscription start */
button.update-subscription-btn.btn.btn-info.btn-lg {
    font-size: 24px;
    color: white;
}

.disabled-plan {
    opacity: 0.5;  
    pointer-events: none; 
    filter: grayscale(100%); 
    user-select: none; 
}

h2.remainder-subscription {
    color: red !important;
    margin-top: 17px;
}

.current-plan {
    text-align: center;
    font-size: 18px;
}

.tutorial-video.col-md-7 {
    margin: auto;
}

.back-button.col-md-7 {
    margin: auto;
    margin-top: 10px;
}
button.btn.btn-primary.tutorial {
    font-size: 14px;
    padding: 10px 24px;
    background-color: #2f95d0;
    font-weight: bold;
}
button.btn.btn-primary.tutorial:hover {
    font-size: 14px;
    padding: 10px 24px;
    background-color: #186896;
    font-weight: bold;
}

button.btn.btn-primary.tutorial:active {
    font-size: 14px;
    padding: 10px 24px;
    background-color: #186896;
    font-weight: bold;
}
/* css for upgrade subscription end */


.question-progress {
    font-size: 18px;
    font-weight: bold;
    color: #444;
    text-align: center;
    padding: 10px 0;
    background: #f8f9fa;
    border-radius: 8px;
    display: inline-block;
    width: auto;
    min-width: 100px;
}

.question-progress span {
    color: #007bff; /* Blue color for numbers */
    font-weight: bold;
}

section#content {
    background-color: #f9f9f9;
}

i.fa-solid.fa-phone {
    position: relative;
    top: 3px;
}

a.btn.btn-success.call {
    height: 34px;
    background-color: #5cb85c;
}


a.resident-edit-resident-1823-blank-form-btn.btn.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
    padding: 6px 12px;
    font-size: 15px;
    float: right;
}


a.booknow {
    position: relative;
    border-color: #428bca;
    color: #357ebd;
    border: 1px solid;
    border-radius: 4px;
    padding: 4px;
}

a.booknow:hover {
    position: relative;
    border-color: #428bca;
    color: white !important;
    text-decoration: none !important;
    border: 1px solid;
    border-radius: 4px;
    background-color: #387db9;
    padding: 4px;
}

a.booknow.btn:active {
    position: relative;
    border-color: #2668a1 !important;
    color: white !important;
    text-decoration: none !important;
    border: 1px solid;
    border-radius: 4px;
    background-color: #387db9 !important;
    padding: 4px;
}

img.cap {
    width: 20px;
}

img.new-button {
    position: absolute;
    left: 4px;
    width: 26px;
    top: -9px;
}

span.book-now {
    font-size: 15px;
    padding: .5em .8em;
}

button.schedulenow:hover {
    font-size: 10px;
    border-radius: 2px;
    border: 1px solid #428bca;
    background-color: #387db9;
    color: white;
}

button.schedulenow:Active {
    font-size: 10px;
    border-radius: 2px;
    border: 1px solid #428bca;
    background-color: #387db9;
    color: white;
}

.print-task {
    cursor: pointer;
}

button.take-course {
    font-size: 15px;
}

a.label.label-outline {
    font-size: 14px;
}

a:hover {
    color: #2a6496 !important;
    text-decoration: underline !important;
}

.modal-dialog.userdata {
    width: 600px;
    height: 400px;
}

.modal-content.userdata {
    max-height: 80vh;
    overflow-y: auto;
    padding: 20px;
}

.modal-header.userdata {
    background-color: white;
    color: #606060;
}

button.btn.btn-info.userdata {
    background-color: #3c84c1;
    border-color: #3c84c1;
    color: white;
    font-size: 18px;
}

button.btn.btn-info.userdata:hover {
    background-color: #3071a9 !important;
    border-color: #3071a9 !important;
    color: white;
    font-size: 18px;
}

button.btn.btn-info.userdata:active {
    background-color: #3071a9 !important;
    border-color: #3071a9 !important;
    color: white;
    font-size: 18px;
}

.btn.btn-warning.no {
    background-color: #ec971f !important;
    border-color: #d58512 !important;
    color: white;
    padding: 7px 13px;
    font-size: 15px;
}

.resident-list__legend__icon img {
    width: 2rem;
}

.resident-list__legend__icon {
    padding: .8rem;
}

.resident-list__legend__description {
    padding-left: 0;
    font-style: italic;
    font-size: 1.3rem;
    color: #606060;
}

/* button.medication.btn.btn-primary {
    font-size: 16px !important;
    background-color: #2F71A8;
    width: auto !important;
    margin-bottom: 5px;
    display: inline-block;
    margin-right: 3px;
} */

button.take-courses {
    border: 1px solid green;
    background-color: white;
    border-radius: 2px;
    font-size: 10px;
    color: green;
}

button.take-course:hover {
    border: 1px solid green;
    background-color: green;
    border-radius: 2px;
    font-size: 15px;
    color: white;
}

button.take-course:active {
    border: 1px solid green;
    background-color: green;
    border-radius: 2px;
    font-size: 15px;
    color: white;
}

.panel-body.edit-form {
    padding: 0px !important;
}

.form-group.edit-form {
    width: auto !important;
    margin-bottom: 5px !important;
}

button.btn.btn-success.resident-edit {
    background-color: #5cb85c;
    border-color: #4cae4c;
    color: white;
}


/* css for mobile responsive start */

/* header css start*/

@media (max-width: 540px) {
    #header {
        padding: 0px !important;
    }
    .header-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .col-4 {
        width: 100% !important;
        display: flex;
        background-color: #047D62;
        border-bottom: 1px solid white;
    }
    
    .col-8 {
        width: 100% !important;
        float: right !important;
    }
    
  
    .menu-button {
        display: flex;
        border-radius: 6px;
        margin: 5px;
    }

    .menu-button:hover {
        display: flex;
        background-color: #047D62;
        border-radius: 6px;
        margin: 5px;
        padding: 3px;
    } 

    .menu-button:active {
        display: flex;
        background-color: #047D62;
        border-radius: 6px;
        margin: 5px;
        padding: 3px;
    } 
  
    /* Show the nav on sidebar toggle */
    .sidebar-active .nav-left {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    li.searchDropdown {
        display: none !important;
    }

    select.w-full.p-2.border.rounded.mb-2 {
        width: 100% !important;
        margin-right: 10px;
        height: 35px !important;
    }
  }


/* Display menu on right after 540px */
@media (max-width: 540px) {
    .menu-button {
        order: 1;
    }
    .logo {
        order: 2;
    }
    
}

@media (min-width: 541px) and (max-width: 767px) {
    .menu-button {
        order: 2 !important;
        margin-left: auto;
    }
    .logo {
        order: 1 !important;
    }
}

/* Default styles for larger screens */
@media (min-width: 768px) {
    .nav-left {
        display: flex;
        justify-content: flex-end;
    }

    .menu-button {
        display: flex;
        order: 2 !important;
    }

    .logo {
        order: 1 !important;
    }
    
    .fa-filter {
        display: inline !important;
    }

    .searchDropdown {
        display: none !important;
    }

 
}

@media (min-width: 768px) and (max-width: 1022px) {
    .nav-left {
        display: flex;
        justify-content: flex-end;
    }

    .menu-button {
        display: flex;
        order: 2 !important;
    }

    .logo {
        order: 1 !important;
    }
}


/* header css end */

/* sidebar css start */

@media (max-width:468px) {
    .sidebar {
        display: none;
    }

    .compliant-image img {
        width: 100%;
        margin-top: 35px;
        transform: rotate(5deg);
    }

    #root.nav-collapsed-min .sidebar {
        width: auto;
        display: block;
        width: 25% !important;
        height: auto;
        position: relative;
    }

    .sidebar {
        background-color: #69788c;
        height: 100vh;
        overflow: auto;
        position: fixed;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
        z-index: 1000;
        transition: all 0.3s ease;
        top: unset !important;
    }
}

  
@media (min-width:469) and (max-width:768) {
    .sidebar {
        display: none;
    }

    .compliant-image img {
        width: 100%;
        margin-top: 35px;
        transform: rotate(5deg);
    }

    .sidebar {
        background-color: #69788c;
        height: 100vh;
        overflow: auto;
        position: fixed;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
        z-index: 1000;
        transition: all 0.3s ease;
        top: unset !important;
    }

}

  .custom-scrollbar.visible {
    display: block; /* Show when toggled */
  }
  
  @media (min-width: 768px) {
    .custom-scrollbar {
      display: block; /* Always show on larger screens */
    }
  }


/* sidebar css end */

/* login css start */

@media (max-width: 768px) {
    label.col-sm-2 {
        width: 100%;
    }
    .signin-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .signin-form {
        max-width: 400px; /* Limit width on larger screens */
        margin-left: 20px; /* Space between logo and form */
    }

    .loginlogo {
        width: 50%;
        height: auto;
        margin-bottom: 30px;
    }

    .form-div {
        width: 100%;
        margin: 0 !important;
    }

    .form-group {
        margin: 0 !important;
        width: 100% !important;
    }

    .loginbuttons {
        display: inline-block !important;
    }
    


    .page-signin .signin-body .btn-primary,
.page-signin .signup-body .btn-primary,
.page-forgot .signin-body .btn-primary,
.page-forgot .signup-body .btn-primary {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 12px 18px;
    background-color: white;
    padding: 12px 18px;
    background-color: white;
    color: #2DB288;
    border: 2px solid #2DB288;
    border-radius: 0;
    font-size: 16px;
    font-weight: bold;
    color: #2DB288;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    width: auto;
}

.page-signin {
    text-align: center;
}

.signin-footer {
    text-align: center;
    width: auto !important;
}

li.searchDropdown {
    display: none !important;
}

   
.resident-label {
    word-break: break-word;
    font-weight: bold;
}
}

@media (min-width:769px) and (max-width: 912px) {

    .signin-form {
        max-width: 400px; /* Limit width on larger screens */
        margin-left: 20px; /* Space between logo and form */
    }

    li.searchDropdown {
        display: none !important;
    }

    .loginlogo {
        float: left;
        height: auto;
        margin-bottom: 30px;
    }

    .form-div {
        width: 100%;
        margin: 0 !important;
    }

    .form-group {
        margin: 0 !important;
        width: 100% !important;
    }


    .page-signin .signin-body .btn-primary,
.page-signin .signup-body .btn-primary,
.page-forgot .signin-body .btn-primary,
.page-forgot .signup-body .btn-primary {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 12px 18px;
    background-color: white;
    padding: 12px 18px;
    background-color: white;
    color: #2DB288;
    border: 2px solid #2DB288;
    border-radius: 0;
    font-size: 16px;
    font-weight: bold;
    color: #2DB288;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    width: auto;
}

.page-signin {
    text-align: center;
    margin-top: 20vh;
}

.signin-footer {
    text-align: left;
    width: auto !important;
}

.col.col-md-5.signin-logo img {
    float: none;
}
.col.col-md-5.signin-logo {
    width: 100%;
}
}


@media (min-width: 1024px) {
    .fa-filter {
        display: none !important;
    }

    .searchDropdown {
        display: inline !important;
    }
}

/* login css end */

/* css for resident dashboard start */

@media (max-width:1023px) {

    .resident-card.card {
        width: 100% !important;
    }

    .resident-tri-annual-card.card {
        width: 100% !important;
    }
}

/* css for resident dashboard start */

/* css for resident list start */

@media (max-width: 912px) {

    .admissionlog.col-md-4.mb-3 {
        font-size: 20px;
        width: 50%;
    }
}



@media (min-width: 768px) and (max-width: 922px) {
    .col-sm-6 {
        width: 50% !important;
    }
}

@media (max-width: 922px) {
    .admissionlog.col-md-4 {
        width: 50% !important;
    }

    .admissionlog.col-md-3 {
        width: 100% !important;
    }

    .panel-section2 {
        width: 50% !important;
    }
    
}

@media (min-width: 990px) {
    .toggle-view {
        position: absolute;
        margin-top: -0.9rem;
        left: calc(50% + 22rem);
    }

    .col-sm-5.col-md-2.mt-3 {
        width: 100% !important;
    }
}

@media (min-width: 990px) {
    

    .toggle-view {
        display: flex;
        justify-content: center;
        gap: 15px;
    }


    .toggle-view img {
        width: 24;  /* Adjust icon size */
        height: 24;
    }

    span.btn.btn-info.ng-scope.print-grid {
        position: absolute;
        right: 24rem;
        padding: 6px 12px;
        font-size: 12px;
        font-weight: bold;
        margin-top: 40px;
    }
        
}


span.btn.btn-info.ng-scope.print-grid {
    position: absolute;
    right: 3rem;
    padding: 6px 12px;
    font-size: 12px;
    font-weight: bold;
    margin-top: 0;
}



.task-scroll
 {
    overflow-x: scroll;
 }

 a.linktask {
    color: #606060;
 }

 a.linktask:hover {
    color: #606060;
 }

 a.linktask:active {
    color: #606060;
 }

 .tutorial-footer {
    margin: auto;
    text-align: center;
    font-size: 15px;
    margin-top: 10px;
    color: #606060;
}

button.btn.btn-primary.tutorial:hover {
    font-size: 14px;
    padding: 10px 24px;
    background-color: #1b86c3 !important;
    font-weight: bold;
}

button.btn.btn-primary.tutorial:active {
    font-size: 14px;
    padding: 10px 24px;
    background-color: #1b86c3 !important;
    font-weight: bold;
}

@media (max-width: 992px) {
.tutorial-video.col-md-7 iframe {
    width: 320px;
    height: 190px;
}
}

@media (max-width: 768px) {
    img.phone{
        display: none !important;
    }

    img.trainings-logo {
        position: sticky;
        margin-top: 10px;
    }

    .col-md-3.col-sm-12 {
        margin-top: 11px;
    }
}

 button.cancelaccess.btn.btn-warning {
    font-size: 17px !important;
}

/* css for invite user form */

.invite-container {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #F9F9F9;
}
  
section.invite-panel {
    margin: auto;
    width: 80%;
    position: relative;
    overflow: hidden;
    min-height: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}     

.invite-panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    text-transform: uppercase;
}

.invite-panel-heading h1{
    font-size: 36px;
    color: #606060;
}

.invite-panel-body {
    padding: 30px;
}

.invite-col-6.col-xs-6 {
    width: 50%;
}

button.btn.btn-lg.btn-block.btn-success.invite-btn {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    padding: 36px 48px;
    font-size: 18px;
}

button.btn.btn-lg.btn-block.btn-success.invite-btn:hover {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    padding: 36px 48px;
    font-size: 18px;
}

button.btn.btn-lg.btn-block.btn-success.invite-btn:active {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    padding: 36px 48px;
    font-size: 18px;
}

.invite-form-group {
    width: 100%;
    margin-bottom: 15px;
}

.invite-form-error {
    color: red;
    font-size: 12px;
    font-weight: bold !important;
}

.employee-viewlink {
    color: #606060;
    text-decoration: none;
}

.employee-viewlink:hover {
    color: #606060;
    text-decoration: none;
}
.employee-viewlink:active {
    color: #606060;
    text-decoration: none;
}


.success-content {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 30px;
}

.success-heading h2 {
    font-size: 36px;
    text-align: center;
}

.success-body p {
    text-align: center;
    font-size: 20px;
}



/* css start for tutorial page mobile responsive*/
@media (max-width: 468px) {
    html, body {
        overflow-y: auto; /* Only allow vertical scrolling */
        overflow-x: hidden; /* Prevent horizontal scrolling */
        width: 100%;
    }

    .content {
        height: auto; /* Let content expand naturally */
        width: 100%;
        max-width: 100vw; /* Prevent extra width */
        padding: 0;
    }

    .panel {
        overflow: visible;
        width: 100%;
        max-width: 100%;
    }

    .tutorials__grid {
        max-width: 100%; /* Prevent it from going beyond screen width */
        display: flex;
        flex-wrap: wrap;
        justify-content: center; /* Keep everything centered */
    }

    .tutorials__grid__box {
        flex: 1 1 100%; /* Ensure full width on small screens */
        max-width: 100%;
        text-align: center;
    }

    .tutorials__grid__box img {
        width: 100%;
        height: auto;
        max-height: 150px;
    }
}


/* css end for tutorial page mobile responsive */

/* css start for ahca regulations mobile responsive */

/* Media Queries for Different Screen Sizes */

/* For tablets and smaller screens */
@media (max-width: 1024px) {
    .tasks.panel {
        padding: 10px;
    }
    .panel-heading .row {
        align-items: flex-start !important;
        text-align: start !important;
       
    }
    .panel-heading-title {
        width: 100%;
    }
    .text-right {
        margin-top: 10px;
    }
}
@media (max-width: 596px) {
    .table-container {
        display: block;
        overflow-x: auto;
    }
    .panel-body {
        padding: 10px;
    }
}
/* For mobile screens */
@media (max-width: 768px) and (min-width: 768px) {
    .table-filters .row {
        align-items: start;
        width:30%;
    }
    .table {
        display: block;
        overflow-x: auto;
    }
    .panel-body {
        padding: 10px;
    }
    .panel-heading .row {
        align-items: flex-start !important;
        text-align: start !important;
       
    }
}

/* For small devices (max-width 480px) */
@media (max-width: 480px) and (min-width: 480px) {
    .pagination {
        flex-direction: column;
        gap: 5px;
    }
    .modal-dialog {
        width: 90%;
    }
    .panel-heading .row{
        align-items: flex-start !important;
        text-align: start !important;
    }
   .input-section{
    width:50%;
   }
}

/* css end for ahca regulations mobile responsive */



  
 
/* css start for edit regulation mobile responsive */

/* css end for edit regulation mobile responsive */

/* employee view responsive start */

@media (max-width: 990px) {
    .col-md-6.left-panel.position-relative {
        width: 100%;
    }

    .col-md-6.right-panel {
        width: 100%;
        display: block;
    }

    ul.list-justified.text-center {
        display: table;
        width: 100%;
        list-style: none;
        padding: 0;
    }

    .employee-view ul li {
        width: 50%;
        display: inline-block;
    }
}


/* employee view responsive end */



td.due_date.date-expired {
    background-color: #c65353 !important;
    padding: 2px;
}

td.due_date.date-soon-expired {
    background: linear-gradient(to bottom, #efbe77 25%, #efbe77 100%);
    padding: 2px;
}

.due_date.date-expired {
    background-color: #c65353 !important;
    padding: 10px;
    color: white !important;
}

.due_date.date-soon-expired {
    background: linear-gradient(to bottom, #efbe77 25%, #efbe77 100%);
    padding: 10px;
    color: white !important;
}




/* responsive for css custom training(same for add task type and edit task type) */

@media (max-width: 990px) {
    .card.mb-5.p-4.shadow-lg.rounded-lg  {
        width: 100%;
    }
}

/* Responsive adjustments for all small screens */
@media (max-width: 480px) {
    .btn-position {
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-wrap: wrap; 
        white-space: normal !important; 
        word-wrap: break-word !important;
        overflow-wrap: break-word !important;
        max-width: 100% !important; 
        min-width: auto; 
        padding: 8px 12px; 
        text-align: center;
    }

    .prerequisites-supersedes-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      overflow-x: hidden;
      padding: 10px;
    }

    /* ✅ Fix for Right Side Extra Spacing */
    .prerequisites-list, .supersedes-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start; 
      width: 100%;
      padding: 10px;
      overflow: hidden;
    }

    /* ✅ Fix for Button Text Cutting & Side Spacing */
    .tag {
      background-color: #007bff;
      color: white;
      padding: 6px 10px;
      border-radius: 5px;
      font-size: 14px;
      white-space: normal !important;
      word-break: break-word !important;
      overflow-wrap: break-word !important;
      max-width: 95% !important; /* ✅ Side spacing fix */
      text-align: center;
      margin: 0 auto; /* ✅ Center align without shifting */
    }

    .panel-body {
      overflow-x: hidden;
      padding: 10px;
    }
}


  
  @media (max-width: 768px) {
    .prerequisites-supersedes-container {
      display: flex;
      flex-direction: column; 
      align-items: center;
      text-align: center;
      width: 100%;
      overflow-x: hidden;
      padding: 10px;
    }
  
    .prerequisites-list, .supersedes-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 100%;
      padding: 10px;
      overflow: hidden;
    }
  
    .tag {
      background-color: #007bff;
      color: white;
      padding: 6px 10px;
      border-radius: 5px;
      font-size: 14px;
      white-space: normal; 
      max-width: 95%;
      text-align: center;
      word-wrap: break-word; 
    }
  
    .panel-body {
      overflow-x: hidden;
      padding: 10px;
    }
  }
  
 
@media (max-width: 1024px) {
    .prerequisites-supersedes-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left;
      width: 100%;
      padding: 15px;
    }
  
    .prerequisites-list, .supersedes-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      overflow-x: hidden; 
      overflow-y: visible; 
    }
  
    .tag {
      font-size: 16px !important; 
      font-family: inherit !important;
      padding: 6px 12px;
      border-radius: 5px;
      white-space: normal;
      word-wrap: break-word;
      text-align: center;
    }
  
    .panel-body {
      overflow-x: hidden; 
    }
  }
  span.btn.btn-position.btn-primary {
    font-size: 15px !important;
}

/* css for course header mobile responsive start */
@media (max-width: 480px) {
    .course-header-section {
      padding: 10px;
    }
  
    .heading-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    /* Facility Name - Left */
    .panel-heading-title {
      font-size: 18px;
      font-weight: bold;
      width: 100%;
      text-align: left; 
    }
  
    /* Home | Courses | Username - Right */
    .panel-heading-actions {
      display: flex;
      justify-content: center; 
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
    }
  
    .panel-heading-actions .header-item {
      margin: 0 10px;
      font-weight: 500;
      text-transform: uppercase; 
    }
  
    .panel-heading-actions strong {
      font-size: 14px;
    }
  }
  
  @media (max-width: 768px) {
    .course-header-section {
        padding: 10px;
    }

    .heading-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    /* ✅ Facility Name - Left (6 Grid) */
    .panel-heading-title {
        font-weight: bold;
        width: 50%; /* 6 Columns */
        text-align: left;
    }

    /* ✅ Home | Courses | Username - Right (6 Grid) */
    .panel-heading-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50%; /* 6 Columns */
        gap: 10px;
    }

    .header-menu {
        display: flex;
        flex-wrap: nowrap;
    }

    .header-user {
        white-space: nowrap;
        text-align: right;
    }

    .header-menu .header-item {
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 34px;
    }
}

@media (max-width: 1024px) {
    .course-header-section {
        padding: 10px;
    }

    .heading-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    /* ✅ Facility Name - Left (6 Grid) */
    .panel-heading-title {
        font-weight: bold;
        width: 50%; /* ✅ 6 Columns */
        text-align: left;
    }

    /* ✅ Home | Courses | Username - Right (6 Grid) */
    .panel-heading-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50%; /* ✅ 6 Columns */
        gap: 10px;
    }

    .header-menu {
        display: flex;
        flex-wrap: nowrap;
    }

    .header-user {
        white-space: nowrap;
        text-align: right;
    }

    .header-menu .header-item {
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 34px;
    }
}


  /* css for course header mobile responsive end */

.sort-arrows {
    cursor: pointer;
}

.employee-link {
    color: #428bca !important;
    font-weight: bold;
}

a.print-cert {
    color: white !important;
}

i.fa-solid.fa-sort {
    cursor: pointer;
}

.pagination {
    float: right;
}

button.btn.btn-disabled.previous {
    border-radius: 0px !important;
    background-color: white;
    border: 1px solid gray;
}

button.btn.btn-disabled.previous:hover {
    border-radius: 0px !important;
    background-color: white;
    border: 1px solid gray;
}

button.btn.btn-disabled.previous:active {
    border-radius: 0px !important;
    background-color: white;
    border: 1px solid gray;
}

button.btn.btn-disabled.next {
    border-radius: 0px !important;
    background-color: white;
    border: 1px solid gray;
}

button.btn.btn-disabled.next:hover {
    border-radius: 0px !important;
    background-color: white;
    border: 1px solid gray;
}

button.btn.btn-disabled.next:active {
    border-radius: 0px !important;
    background-color: white;
    border: 1px solid gray;
}

.btn.btn-primary.page-no {
    border-radius: 0px !important;
}

.btn.btn-primary.page-no:hover {
    border-radius: 0px !important;
}

.btn.btn-primary.page-no:active {
    border-radius: 0px !important;
}

.btn:disabled,
.btn[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
    pointer-events: none;
}


.expired {
    background-color: #c65353 !important;
    padding: 2px;
}
.date-soon-expire {
    background: linear-gradient(to bottom, #efbe77 25%, #efbe77 100%);
    padding: 2px;
    color: white !important;
    font-weight: bold;
}

img.course-image {
    width: 100% !important;
}

.panel-bodyy {
    padding: 15px;
}



/* employee edit responsive start */

@media (min-width: 766px) and (max-width: 1020px) {

    .col-md-7.employee-edit {
        width: 100% !important;
    }

    .col-md-12.positions {
        width: 100% !important;
    }

    .col-md-2.position {
        width: 100% !important;
    }

    .col-md-4.training-event {
        width: 100% !important;
    }

    .col-md-6.training-event {
        width: 100% !important;
    }

    .col-md-3.training-event {
        width: 100% !important;
    }

    .col-md-5.training-event {
        width: 100% !important;
    }


}

@media (min-width: 300px) and (max-width:767px) {
    
    .col-md-6.course-details {
        width: 100% !important;
    }

    .col-md-6.course-image-section {
        width: 100% !important;
    }

    img.course-image {
        width: 100% !important;
    }

    .col-md-3.course-sidebar {
        width: 100% !important;
    }

    .col-sm-1 {
        width: 100% !important;
    }

    .col-sm-3 {
        width: 100% !important;
    }

    .panel-body.edit-form {
        overflow-x: visible !important;
    }

    .col-md-6 {
        width: 100% !important;
    }

    .resident-add-form.row {
        padding: 4px 8px;
        width: 100%;
    }
}


@media (min-width: 768px) and (max-width: 990px) {

    .panel-body.edit-form {
        overflow-x: visible !important;
    }

    .col-md-6.course-details {
        width: 100% !important;
    }

    .col-md-6.course-image-section {
        width: 100% !important;
    }

    img.course-image {
        width: 100% !important;
    }


}

/* css for course sidebar */


@media (max-width: 480px) {
    .sidebar-course-item-list {
        overflow-y: scroll;
        height: auto !important;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    .course-items-section {
        display: block;
        transition: all 0.3s ease-in-out;
    }

    .course-items-section.hidden {
        display: none !important;
    }
    .nav-collapsed-min .sidebar-course-item-list{
        display: none;
    }
    .course-name.w-40 {
        width: 40% !important;
    }
    .col-md-4.text-right.ex-4{
        margin-top: -51px !important;
    }
    strong.course-item-name {
        margin-bottom:0% !important
        
    }
    strong.course-item-name {
        font-size: 25px !important; 
    }
    
    
}

@media (max-width: 768px) {
    .sidebar-course-item-list {
        overflow-y: scroll;
        height: auto !important;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }

    .course-items-section {
        display: block;
        transition: all 0.3s ease-in-out;
    }

    .course-items-section.hidden {
        display: none !important;
    }

    .nav-collapsed-min .sidebar-course-item-list {
        display: none;
    }

    .course-name.w-40 {
        width: 47% !important;
    }

    .col-md-4.text-right.ex-4 {
        /* width: 79% !important; */
        margin-top: -58px !important;
}


}
@media (min-width: 768px) {
    .col-md-9.text-right.header-menu {
        width: -1% !important;
    }
}
@media (min-width: 821px) and (max-width: 1024px) {
    .sidebar-course-item-list {
        overflow-y: scroll;
        height: auto !important;
        max-height: 80vh; /* Ensures it doesn't exceed viewport */
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }

    /* ✅ When Sidebar is Collapsed, Adjust Width */
    .nav-collapsed-min .sidebar-course-item-list {
        display: none; /* ✅ Ensures sidebar does not take full space */
    }

    .nav-collapsed-min .course-items-section {
        width: calc(100% - 80px); /* ✅ Adjust content width when sidebar is closed */
        margin-left: 80px; /* ✅ Prevents overlap */
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .col-md-9.text-right.header-menu {
        width: 70% !important; /* ✅ Set a fixed width for consistency */
    }

}

@media (max-width: 1024px) {
    .sidebar-course-item-list {
        overflow-y: scroll;
        height: auto !important;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    .course-items-section {
        display: block;
        transition: all 0.3s ease-in-out;
    }

    .course-items-section.hidden {
        display: none !important;
    }

    .nav-collapsed-min .sidebar-course-item-list {
        display: none;
    }

    .course-name.w-40 {
        width: 47% !important;
    }

    .col-md-4.ex-4 {
        margin-top: 9px !important;
    }
}
@media (max-width: 360px) {
    .course-name.w-40 {
        width: 50% !important;  /* 768px वाली width को override करने के लिए */
    }

    .col-md-4.text-right.ex-4 {
        margin-top: -52px !important;
        width: 100% !important;
    }
    
        .col-md-9.text-right.header-menu {
            width: 68% !important;
        }
}



/* css for course sidebar ends */
/* css start for boolean mobile responsive */

/* Media query for screens below 768px */
@media (max-width: 768px) {
    .boolean-options {
        flex-direction: row; /* Stack the buttons vertically */
    }

    .boolean-options .MuiButton-root {
        width: 100%; /* Make buttons full width */
        max-width: 300px; /* Max width to avoid excessive stretching */
        margin-bottom: 15px; /* Add space between buttons */
        font-size: 1.2rem; /* Increase the font size */
        padding: 10px; /* Add more padding to make the buttons bigger */
       
    }

    .subscription-modal-content-wrap:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
    }

    .col.col-md-5 {
        flex: 0 0 auto;
        width: 100%;
        margin: auto;
    }

    .col.col-md-7.signin-form {
        width: 100% !important;
    }

    
    
    .col.col-md-5.signin-logo img {
        float: none;
    }

    img.forgot-logo {
        float: none !important;
    } 
}


/* css end for boolean mobile responsive */

.sponsorshiplink {
    color: #428bca !important;
    text-decoration: underline !important;
}

span.bigger {
    font-size: 1.5em;
    font-weight: 600;
}

.modal-dialog.subscribe {
    height: 92vh;
}

.modal-content.subscribe {
    height: 100%;
    padding: 0 !important;
}

button.btn.btn-success.subscribe-btn {
    margin: auto;
}

/* css for susbcription repsonsive */

@media (min-width: 300px) and (max-width: 990px) {

    .form-group.username-field {
        width: 100% !important;
        margin: auto !important;
    }
    .form-group.password-field {
        width: 100% !important;
        margin: auto !important;
    }
    
    .content-container.sponsorship {
        display: flex;
        flex-direction: row;
        margin-top: 74px;
        justify-content: center;
        text-align: center;
    }

    .subscribe-now {
        margin-right: 0 !important;
        margin-top: 93px;
        text-align: initial;
    }

    .subscribe-now {
        margin-top: 10px;
        text-align: initial;
        display: flex;
        justify-self: center;
        justify-content: center;
    }

    .col-sm-12 {
        width: 100% !important;
    }

    button.btn.btn-info.btn-subscribe {
        width: 310px;
        display: flex;
        justify-self: center;
        justify-content: center;
    }

    button.btn.btn-info.btn-subscribe.green {
        width: 310px;
        display: flex;
        justify-self: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .phone {
        display: none;
    }

    img.trainings-logo {
        position: sticky !important;
        margin-top: 10px;
    }

    .col-md-3.col-sm-12 {
        margin-top: 11px;
    }

    .modal-dialog.subscribe {
        height: 92vh;
    }

    .modal-content.subscribe {
        height: 100%;
        padding: 0 !important;
    }

    button.btn.btn-success.subscribe-btn {
        margin: auto;
    }

    .modal-dialog.subscribe {
        height: 92vh;
    }

    img.subscription-modal-content-logo {
        width: 7rem;
        height: auto;
        position: absolute;
        right: 31px;
    }

    
    button.btn.btn-info.btn-lg.col-xs-offset-4 {
        padding: 5px;
    }

    .modal-dialog.subscribe {
        height: 92vh;
    }

    .modal-content.subscribe {
        height: 100%;
        padding: 0 !important;
    }

    .modal-dialog.susbcribe .subscription-modal-content {
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .subscription-modal-content-header.row {
        width: 100%;
        color: white;
        font-weight: bold;
    }

    .subscription-modal-content-header.row img.subscription-modal-content-logo {
        width: 7rem;
        height: auto;
        position: absolute;
        right: 31px;
    }

    button.btn.btn-success.subscribe-btn {
        margin: auto;
    }

    .staff-plans {
        width: 100%;
    }

    .subscription-plans-plan {
        padding: 0;
    }
    .subscription-plans-plan-header p span {
        color: #586e6e;
        font-size: 2rem;
    }

    .subscription-plans-plan-param p span {
        color: #586e6e;
        font-size: 1.1rem;
    }

    .subscription-plans-frequencies p {
        color: #586e6e;
        font-size: 1.4rem;
    }

    button.btn.btn-info.btn-lg.col-xs-offset-4 {
        padding: 5px;
        margin: 0 !important;
        display: flex !important;
        justify-self: center;
    }

    .subscription-heading {
        margin-top: 0px;
    }

    h1.subscription-head {
        font-size: 2.8em;
    }
    .plan-amount {
        font-size: 2em;
    }

    .subscribe-now.staff {
        margin: 0;
        display: flex;
        justify-self: center;
    }
    button.btn.btn-info.btn-lg.col-xs-4.col-xs-offset-4 {
        margin: 0 !important;
        width: 100%;

    }

    .subscription-content {
        background-position-x: right !important;
    }

    .subscription-contents {
        min-height: 100vh;
        width: 100%;
        color: #000;
        margin-bottom: 0;
        background-size: cover !important;
        padding: 10px;
    }
    
    .subscription-heading {
        margin-top: 0px;
    }
    
    h1.subscription-head {
        font-size: 2.8em;
    }
    .plan-amount {
        font-size: 2em;
    }

    .col-md-offset-4.col-md-8 {
        width: 100%;
    }

    .cta.trial {
        margin: 50px 0px 10px 0px;
    }
    .subscription-h1 {
        font-size: 29px;
    }

    img.trainings-logo {
        width: 7rem;
        display: flex;
        justify-self: center;
    }

    
    .subscription-content {
        min-height: 100vh;
        width: 100%;
        color: #000;
        margin-bottom: 0;
        background-size: cover !important;
        padding: 10px;
        background-position-x: right !important;
    }

    .subscription-contents {
        min-height: 100vh;
        width: 100%;
        color: #000;
        margin-bottom: 0;
        background-size: cover !important;
        padding: 10px;
    }

    img.trainings-logos {
        margin: auto;
        width: 7rem !important;
        display: flex;
        justify-self: center;
    }
    .col-md-6.mt-3.mb-3 {
        width: 100% !important;
    }

    .col-xs-6 {
        width: 50% !important;
    }

    .cover__header-content-info {
        max-width: 100%;
    }

    .course-name {
        margin-bottom: 20px;
        padding: 20px !important;
    }

    .css-17mt9ff {
        width: 100% !important;
    }

    .col.panel-heading-title {
        width: 100%;
    }

    .eventheading {
        text-transform: uppercase;
        font-size: 24px;
        text-align: center;
    }  
     
     

}



@media (min-width: 767px) and (max-width: 1196px) {
    .subscription-plans-plan-header p span {
        color: #586e6e;
        font-size: 2.5rem;
    }

    .subscription-plans-plan-param p span {
        color: #586e6e;
        font-size: 1.5rem;
    }

    .subscribe-now.staff {
        display: flex;
        justify-self: center;
    }

    .col-lg-offset-4.col-lg-8.col-xs-12 {
        width: 100%;
    }
    .col-xs-6 {
        width: 50% !important;
    }

}

@media (min-width:767px) and (max-width: 990px) {
    .col-md-8.subscription-heading {
        width: 100%;
    }

    .col-md-5.plans{
        width: 100%;
    }
    .col.col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
        margin: auto;
    } 
    .page-signin,
.page-forgot {
    width: 80%;
    margin-top: 20vh;
}
}

.subscribe-now.staff {
    margin: 0;
    display: flex;
    justify-self: center;
}
button.btn.btn-info.btn-lg.col-xs-4.col-xs-offset-4 {
    margin: 0;
    width: 100%;
}

@media (min-width: 992px) {
    img.trainings-logos {
        position: absolute;
        bottom: 63px;
        right: 40px;
        width: 7rem !important;
    }

    img.trainings-logo {
        position: absolute;
        bottom: 63px;
        right: 40px;
    }

    
    .form-group {
        width: 100% !important;
        margin: 0;
    }

    .col-xs-6 {
        width: 50%;
    }
    .col-md-6.mt-3.mb-3 {
        width: 100% !important;
    }
}

@media (min-width: 1670px) {
    .cta.trial{
        padding-left: 228px;
    }
}

@media (min-width: 900px) and (max-width: 1199px){
    .form-group {
        width: 100% !important;
        margin: 0 !important;
    }

    .col-xs-12.col-lg-6 {
        width: 100%;
    }
    .col-md-6.mt-3.mb-3 {
        width: 100%;
    }
    .col-xs-6 {
        width: 50% !important;
    }

    .form-group {
        width: 100% !important;
        margin: 0;
    }
}

label.resident-label {
    word-break: break-word;
}

.subscription-plans-plan.active {
    border: 3px solid #30d730;
}

.update-subscription-btn.btn.btn-info.btn-lg {
    padding: 16px 20px;
}

button.btn.btn-info.btn-lg.col-xs-offset-4 {
    margin: 0 !important;
}

.business-agreement {
    padding: 10px;
}

table.table.employee-detail-header thead tr th {
    text-align: start;
}



@media (min-width: 300px) and (max-width:468) {
    #root.nav-collapsed-min .sidebar {
        width: auto;
        display: block;
        width: 25% !important;
        height: auto;
        position: relative;
    }
}


/* css for sidebar */
@media (min-width: 300px) and (max-width: 991px){
div#course-nav {
    display: none !important;
}

.col-sm-6.col-md-2.mt-3 {
    width: 100% !important;
}
.col-sm-6.col-md-4.mt-3 {
    width: 100% !important;
}
.col-sm-6.col-md-10.mt-3 {
    width: 100% !important;
}
.col-sm-6.col-md-2.mt-3 {
    width: 100% !important;
}
}
/* css for sidebar end  */



/* css for responsive summary page start */
@media (min-width: 360px) and (max-width: 740px) {
    strong.course-item-name.summary-page {
        display: flex; 
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 20px !important; 
        font-weight: bold; 
        width: 100%;
    }
    .col-md-6.summary-page {
        width: 100% !important;
    }
}
@media screen and (min-width: 300px) and (max-width: 768px) {
    .course-status {
        font-size: 13px !important;
        width: 100%  !important;

    }
    .success-image {
        background-color: #f5f5f5;
        width: 44% !important;
        margin: auto;
    }
    .course-summary {
        width: 100% !important;
        margin: auto;
    }
    strong.course-item-name.summary-page {
        justify-content: center;
        text-align: center;
        display: block; 
        width: 100%;
    } 
    .col-md-6.summary-page {
        width: 100% !important;
        text-align: center !important;
    }

    section.invite-panel {
        margin: auto;
        width: 80%;
        position: relative;
        overflow: hidden;
        min-height: 100%;
        margin-bottom: 20px;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    }

}
@media (min-width: 820px) and (max-width: 1024px) {
    .col-md-6.summary-page {
        width: 100% !important;
        text-align: center !important;
    }
    }



/* css for responsive summary page ends */

/* css for course signature page start */
@media (min-width: 360px) and (max-width: 740px) {
    .sig-flex {
        margin-left: 0px !important; 
    }
    .sig-signature-instruction {
        margin: 0px 0px !important;
    }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .sig-flex {
     margin-left: 0px !important; 
    }
    .sig-signature-instruction {
        margin: 0px 0px !important;
    }
    button.facesheet.resident-edit-examiner-btn.btn.btn-primary {
        margin-top: 20px !important;
    }

}
@media (max-width: 1024px) and (min-width: 768px) {
    p.sig-signature-instruction {
        margin: 0px 100px !important;
        font-size: 12px !important;
    }
}
@media (min-width: 820px) and (max-width: 1024px) {
    .sig-flex {
        margin-left: 0px !important; 
       }
    }

/* css for course signature page end */


@media (max-width: 767px) {
    

    .col-sm-2.signup-page  {
        text-align: right; 
        flex-shrink: 0;
        margin-top:5px;
        width:auto !important;
    }

   
    input.check-signup {
        margin-right: 7px;
    }
}

@media (min-width: 768px) {
    .col-sm-2.signup-page  {
        text-align: right; 
        flex-shrink: 0;
        margin-top:5px;
    }

   
    input.check-signup {
        margin-right: 7px;
    }
}


@media (min-width: 300px) and (max-width: 600px) {

    a.resident-edit-resident-1823-blank-form-btn.btn.btn-info {
        font-size: 11px;
        padding: 4px !important;
    }

    button.resident-edit-resident-1823-form-btn.btn {
        font-size: 11px;
        padding: 4px !important;
    }

    .panel-body {
        overflow-x: unset;
    }

    table.key-table tbody tr td {
        font-size: 10px;
    }

    .course-status h2 {
        padding: 0 !important;
    }

    .row.items-data{
        padding: 0 !important;
    }
}

button.btn.btn-warning.btn-sm {
    background-color: #28b724 !important;
    color: #fff !important;
    border-color: #1dbe62 !important;
    font-size: 12px;
}

button.btn.btn-warning.btn-sm:hover {
    background-color: #1c8b19 !important;
    color: #fff !important;
    border-color: #1c8b19 !important;
    font-size: 12px;
}
button.btn.btn-warning.btn-sm:active {
    background-color: #1c8b19 !important;
    color: #fff !important;
    border-color: #1c8b19 !important;
    font-size: 12px;
}

.file-grid-certificates-certificate-name {
    font-weight: bold;
    font-size: 15px;
    color: #606060;
}

.file-grid-certificates-certificate-date {
    font-weight: bold;
    font-size: 15px;
    color: #606060;
}


button.btn.btn-disabled.next:active {
    border-radius: 0px !important;
    background-color: #6e6d6d !important;
    border: 1px solid #4f4e4e;
    color: white;
}

button.btn.btn-disabled.previous:active {
    border-radius: 0px !important;
    background-color: #6e6d6d !important;
    border: 1px solid #4f4e4e;
    color: white;   
}


p.validity-period.text-danger.mt-1 {
    font-weight: bold !important;
    font-size: 12px !important;
}

img.trainings-logo {
    position: absolute;
    top: 644px;
}

.col-md-3.col-sm-12 {
    width: auto;
}

@media (min-width: 991px) {
    .col-sm-6.col-md-2.mt-3 {
        width: 100% !important;
    }
    .col-sm-6.col-md-10.mt-3 {
        width: 100%;
    }
}

/* footer css start */

section#footers {
    display: none; 
}

.footer-logo {
    width: 100px;
}

p.footer-heart {
    text-align: center;
    padding-top: 10px;
    font-weight: 600;
}

.site-link{
    color: #3d87c7;
}

img.emoji {
    width: 20px !important;
    height: 20px !important;
}

/* footer css end */

p.marquee-text {
    color: red;
    font-size: 20px;
    display: inline-block;
    padding-right: 20px;
}

input.employee-search.form-control.search-input {
    font-size: 16px !important;
}

.mob-head.col-4.d-flex.flex-lg-row.align-items-center {
    background-color: #027D61 !important;
}
.course.grid-layout {
    padding: 0px !important;
}

@media (max-width: 600px) {
    .grid-layout {
        display: grid;
        grid-template-columns: unset !important;
        gap: 20px;
        padding: 20px;
    }

    .course-infoo {
        flex-direction: column;
    }
}


p.textmessage {
    text-align: center;
    color: black;
    font-size: 15px;
}

.message-box {
    width: 50%;
    margin: auto;
    padding: 20px;
    border: 2px solid #e1e126;
    border-radius: 5px;
    background-color: #f7f7dc;
    margin-bottom: 20px;
}

i.fa-solid.fa-triangle-exclamation {
    font-size: 50px;
    color: #d4d408;
}

button.btn.btn-primary.btn-sm.status {
    background-color: white;
    border: 2px solid #0d6efd;
}
button.btn.btn-primary.btn-sm.status:hover {
    background-color: #0d6efd !important;
    border: 2px solid #0d6efd !important;
    color: white;
}
button.btn.btn-primary.btn-sm.status:active {
    background-color: #0d6efd !important;
    border: 2px solid #0d6efd !important;
    color: white;
}
i.fa.fa-graduation-cap.text-white {
    color: blue !important;
    font-size: 12px;
}
i.fa.fa-graduation-cap.text-white:hover {
    color: white !important;
    font-size: 12px;
}
i.fa.fa-graduation-cap.text-white:active {
    color: white !important;
    font-size: 12px;
}

.notice-logo {
    margin: auto;
    width: 10%;
}

.loader-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

  .loader-text {
    margin-top: 16px;
    font-size: 34px !important;
    color: #333;
    font-weight: bold;
}
.loader-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
  }

/* General Styles */
.dashboard-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    text-align: center;
    background: linear-gradient(to right, #09a381, #04879580);
    color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Heading Styles */
.dash-heading-text {
    font-size: 5.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: capitalize;
    animation: fadeInDown 1s ease-in-out;
}

/* Paragraph Styles */
.dash-para {
    max-width: 600px;
    font-size: 1.2rem;
    line-height: 1.6;
    margin-top: 15px;
    animation: fadeInUp 1s ease-in-out;
}

/* Paragraph Text */
.para-text {
    margin-bottom: 10px;
    font-size: 2rem;
}

/* Loader Overlay */
.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
}

/* Animations */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

i.fa-solid.fa-screwdriver-wrench {
    font-size: 40px;
}

.dashboard-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

i.icon-size {
    font-size: 25px;
}

h5.card-title {
    font-size: 20px;
}

p.card-text {
    font-size: 15px;
}

a.card-link {
    color: #2a6496 !important;
    text-decoration: none !important;
}

.card-body {
    padding: 20px;
}


.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 72%);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

img.dashboard-images {
    width: 100%;
    object-fit: fill;
}

.faq-heading {
    font-size: 18px;
    font-weight: bold;
}

i.fa-solid.fa-user-lock.me-2.text-primary {
    font-size: 18px;
    color: #034ee0;
}

i.fa-solid.fa-graduation-cap.me-2.text-success {
    font-size: 18px;
}
i.fa-solid.fa-gears.me-2.text-warning {
    font-size: 18px;
}

li.faq-list {
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
}

small.training-left {
    float: right;
    font-size: 18px !important;
}

.pagination-button.active {
    background-color: #428ACA; 
    color: white; 
    border: 1px solid #428ACA; 
  }
  button.pagination-button-first {
    color: #b3b3b3;
    background-color: #fff;
    border-color: #ddd;
    margin-left: 1px;
    padding: 5px 10px;
    font-size: 12px;
    border: 1px solid #ddd;
}

  button.pagination-button-first.active {
    color: #4389CA;
    background-color: #fff;
    border-color: #ddd;
    margin-left: 1px;
    padding: 5px 10px;
    font-size: 12px;
    border: 1px solid #4389CA;
}
button.pagination-button-last {
    color: #b3b3b3;
    background-color: #fff;
    border-color: #ddd;
    margin-left: 1px;
    padding: 5px 10px;
    font-size: 12px;
    border: 1px solid #ddd;
}

  button.pagination-button-last.active {
    color: #4389CA;
    background-color: #fff;
    border-color: #ddd;
    margin-left: 1px;
    padding: 5px 10px;
    font-size: 12px;
    border: 1px solid #4389CA;
}